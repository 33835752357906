import React, {Component} from 'react';
import PartnersSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default class PartnersListSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoerror: null,
            customerlogos: []
        }
    }

    componentDidMount() {
        const apiUrl = 'https://www.evisionstore.com/api/home/dashboard.php';

        fetch(apiUrl)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    customerlogos: result.home_brands
                });
            },
            (error) => {
                this.setState({ logoerror: error });
            }
        )
    }

    render(){
        const { logoerror, customerlogos } = this.state;

        const partnersslidersettings = {
            infinite: true,
            autoplay: true,
            speed: 600,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true
                  }
                }
            ]
        };

        return(
            <section className="partner-container">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="product-title">
                        <h2>Busca por Marca</h2>
                    </div>
                    </div>
                    <section className="customer-logos">
                        <PartnersSlider {...partnersslidersettings}>
                            {customerlogos.map(customerlogos => (
                                <div key={customerlogos.brand_id}>
                                    <Link to={`/brandshop/${customerlogos.brand_name.toString().toLowerCase()}`} ><picture><img src={customerlogos.brand_image} alt={customerlogos.brand_name}/></picture></Link>
                                </div>
                            ))}
                        </PartnersSlider>
                    </section>
                </div>
                </div>
            </section>
        )
    }
}