import React from "react";
import { Link, Redirect } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import {Card, Form, Button} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PrivacyPolicy from '../Include/PrivacyPolicy';
import TermsAndConditions from '../Include/TermsConditions';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { connect } from 'react-redux';
import { addBillingInfo, addShippingInfo, submitCardPaymentForm, submitBacCardPaymentForm, submitBankTransferForm, submitYappyForm, submitClaveForm } from '../../Services/Actions/checkout.actions';
import publicIp from "public-ip";
import { Helmet } from "react-helmet";
import API_BASE_URL from '../../config/api';

// const mapStyles = {
// width: '96%',
// height: '100%',
// position: 'relative'
// };

class CheckoutListingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartProducts: this.props.cartProducts,
            customerId: this.props.customerId,
            errors: {}, responseSuccess: {},
            couponLoader: "hidden",
            isBillingShippingFormSubmitted: false,
            billingFirstName: '', billingLastName: '', billingEmail: '', billingCompany: '', billingAddress1: '',
            billingAddress2: '', billingCity: '', billingState: '', billingZipCode: '', billingCountry: '',
            billingAddInfo: '', billingPhone: '', billingAddressTitle: '',
            isShippingAsBillingChecked: false,
            isShippingFormSubmitted: false,
            shippingFirstName: '', shippingLastName: '', shippingEmail: '', shippingAddress1: '', shippingAddress2: '',
            shippingCity: '', shippingState: '', shippingZipCode: '', shippingCountry: '', shippingPhone: '', fullshippingPhone: '',
            countryItems: [], stateItems: [], shippingStateItems: [], cityItems: [], shippingCityItems: [],
            isDeliveryFormSubmitted: false,
            deliveryType: '', deliveryTypeItems: [], zoneId: '', deliveryCost: 0, pickupStoreId: '',
            isCardPaymentFormSubmitted: false,
            ccnumber: '', cardExpMonth: '', cardExpYear: '', cvv: '', cardName: '',
            bacCardNumber: '', bacCardExpMonth: '', bacCardExpYear: '', bacCardCvv: '', bacCardName: '',
            cardExpMonthYear: '', bacCardExpMonthYear: '',
            isAllowBacCredomatic: false, cartProductsArray: [], cartProductsDetails: '', cartProductIds: {},
            couponCode: '', discountType: '', discountAmount: '', discountFor: '',
            isCredTermsAndConditionChecked: false, isBacCredTermsAndConditionChecked: false, isBankTermsAndConditionChecked: false, isYappyTermsAndConditionChecked: false, isClaveTermsAndConditionChecked: false,                  
            credTermsAndConditionError: '', bacCredTermsAndConditionError: '', bankTermsAndConditionError: '', yappyTermsAndConditionError: '', claveTermsAndConditionError: '',
            credButtonDisabled : false, bacCredButtonDisabled : false, bankButtonDisabled : false, yappyButtonDisabled : false, claveButtonDisabled : false,
            paymentLoader: 'hidden', couponButtonDisabled: false,
            showTermsAndConditionsModal: false, showPrivacyPolicyModal: false, isProductsAddedToCart: true,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            clientIpAddress: false,
            deliveryCharge: 0,
            customerDeliveryType: '*'
        }

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnShippingInputChange = this.handleOnShippingInputChange.bind(this);
        this.handleOnCouponSubmit = this.handleOnCouponSubmit.bind(this);
        this.handleOnBillingShippingFormSubmit = this.handleOnBillingShippingFormSubmit.bind(this);
        this.handleOnCardPaymentFormSubmit = this.handleOnCardPaymentFormSubmit.bind(this);
        this.handleOnBacCardPaymentFormSubmit = this.handleOnBacCardPaymentFormSubmit.bind(this);
        this.handleOnBankTransferFormSubmit = this.handleOnBankTransferFormSubmit.bind(this);
        this.handleOnYappyFormSubmit = this.handleOnYappyFormSubmit.bind(this);
        this.handleOnClaveFormSubmit = this.handleOnClaveFormSubmit.bind(this);

        this.handleOnAcceptTermsAndConditionInputChangeForCred = this.handleOnAcceptTermsAndConditionInputChangeForCred.bind(this);
        this.handleOnAcceptTermsAndConditionInputChangeForBakCred = this.handleOnAcceptTermsAndConditionInputChangeForBakCred.bind(this);
        this.handleOnAcceptTermsAndConditionInputChangeForBank = this.handleOnAcceptTermsAndConditionInputChangeForBank.bind(this);
        this.handleOnAcceptTermsAndConditionInputChangeForYappy = this.handleOnAcceptTermsAndConditionInputChangeForYappy.bind(this);
        this.handleOnAcceptTermsAndConditionInputChangeForClave = this.handleOnAcceptTermsAndConditionInputChangeForClave.bind(this);
        
        this.termsAndCOnditionsPopupOpen = this.termsAndCOnditionsPopupOpen.bind(this);
        this.termsAndCOnditionsPopupClose = this.termsAndCOnditionsPopupClose.bind(this);
        this.privacyPolicyPopupOpen = this.privacyPolicyPopupOpen.bind(this);
        this.privacyPolicyPopupClose = this.privacyPolicyPopupClose.bind(this);
        this.handleOnBillingTelInputChange = this.handleOnBillingTelInputChange.bind(this);
        this.handleOnShippingTelInputChange = this.handleOnShippingTelInputChange.bind(this);
    }

    getClientIp = (async () => {
        const ipAddress = await publicIp.v4();
        this.setState({clientIpAddress: ipAddress});
    })();

    componentDidMount() {
        //console.log('IP:', getClientIpAddress);
        if(this.state.customerId){
            this.setState({paymentLoader: ''});
            //const apiCountryUrl = 'https://www.evisionstore.com/api/checkout/getcountry.php';
            //const apiAddressUrl = "https://www.evisionstore.com/api/checkout/getaddress.php";
            //const apiCheckoutUrl = "https://www.evisionstore.com/api/checkout/getorderreview2.php";
            const apiCountryUrl = `${API_BASE_URL}/provinces`;
            const apiAddressUrl = `${API_BASE_URL}/customer/address`;
            const apiCheckoutUrl = `${API_BASE_URL}/cart/review`;

            const requestOptionsForAddress = {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({  "customer_id": this.state.customerId })
            };

            Promise.all([
                fetch(apiCountryUrl, {method: 'POST'}),
                fetch(apiAddressUrl, requestOptionsForAddress)
            ]).then(([res1, res2]) => {
                return Promise.all([res1.json(), res2.json()])
                // return Promise.all(responses.map(function (response) {
                //     return response;
                // }));
            }).then(([res1, res2]) => {
                let deliveryType;
                if(res2.data.checkout_address[0].delivary_type === "Pickup"){
                    deliveryType = '1';
                }else if(res2.data.checkout_address[0].delivary_type === "Delivery"){
                    deliveryType = '2';
                }else{
                    deliveryType = '0';
                }
                
                this.setState({
                    paymentLoader: 'hidden',
                    countryItems: res1.data,
                    billingFirstName: res2.data.checkout_address[0].first_name,
                    billingLastName: res2.data.checkout_address[0].last_name,
                    billingEmail: res2.data.checkout_address[0].email,
                    billingAddress1: res2.data.checkout_address[0].address_1,
                    billingAddress2: res2.data.checkout_address[0].address_2,
                    billingCountry: res2.data.checkout_address[0].country_id,
                    billingState: res2.data.checkout_address[0].state_id,
                    billingCity: res2.data.checkout_address[0].city_id,
                    billingPhone: res2.data.checkout_address[0].telephone,
                    billingCompany: res2.data.checkout_address[0].company,
                    billingZipCode: res2.data.checkout_address[0].pincode,
                    zoneId: res2.data.checkout_address[0].zone,
                    pickupStoreId: res2.data.checkout_address[0].pickup_id,
                    deliveryCost: res2.data.checkout_address[0].delivary_cost,
                    deliveryType: deliveryType
                });
                
                const country_id = this.state.billingCountry;
                //const apiUrl = 'https://www.evisionstore.com/api/checkout/getstate.php';
                const apiUrl = `${API_BASE_URL}/states-by-country`;

                return fetch(apiUrl, {
                    method: 'POST',
                    body: JSON.stringify({"country": country_id})
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            stateItems: result.data
                        });

                        const state_id = this.state.billingState;
                        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcitybycountry.php';
                        const apiUrl = `${API_BASE_URL}/city-by-country`;
                        return fetch(apiUrl, {
                            method: 'POST',
                            body: JSON.stringify({"country": country_id})
                        })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    cityItems: result.data
                                });

                                let delType = this.state.deliveryType;
                                if(delType) {
                                    if(delType === "1"){
                                        delType = "pickup";
                                    }else if(delType === "2"){
                                        delType = "delivery";
                                    }else{
                                        delType = "";
                                    }
                                    //const apiUrls = 'https://www.evisionstore.com/api/checkout/getdelivery.php';
                                    const apiUrls = `${API_BASE_URL}/get-delivery`;
                        
                                    return fetch(apiUrls, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            "delivery_type": delType, 
                                            "customer_id": this.state.customerId, 
                                            "country_id": this.state.billingCountry,
                                            "state_id": this.state.billingState,
                                            "city_id": this.state.billingCity
                                        })
                                    })
                                    .then(rest => rest.json())
                                    .then(
                                        (result) => {
                                            this.setState({
                                                deliveryTypeItems: result.data
                                            });

                                            const requestOptionsForCheckout = {
                                                method: 'POST', 
                                                headers: { 'Content-Type': 'application/json'},
                                                body: JSON.stringify({  
                                                    "customer_id": this.state.customerId, 
                                                    "delivery_type": delType, 
                                                    "delivery_amount": result.data[0].delivery_cost
                                                })
                                            };

                                            return fetch(apiCheckoutUrl, requestOptionsForCheckout)
                                            .then(rest => rest.json())
                                            .then(
                                                (result) => {
                                                    if(result.code === 200){
                                                        this.setState({
                                                            isAllowBacCredomatic: result.data.is_allow_bac_credomatic,
                                                            cartProductsArray: result.data.order_review,
                                                            cartProductsDetails: result.data.order_totals[0],
                                                            cartProductIds: result.data.product_items,
                                                            paymentLoader: 'hidden'
                                                        })
                                                    }else{
                                                        this.setState({
                                                            isProductsAddedToCart: false,
                                                            paymentLoader: 'hidden'
                                                        });
                                                    }
                                                }
                                            )
                                        }
                                    )
                                } 
                            },
                            (error) => {
                                this.setState({ error });
                            }
                        )
                    },
                    (error) => {
                        this.setState({ error });
                    }
                )


            }).catch(function (error) {
                //console.log(error); 
            });

            

        }else{
            //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcountry.php';
            const apiUrl = `${API_BASE_URL}/provinces`;

            fetch(apiUrl, {
                method: 'POST'
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        countryItems: result.data
                    });
                },
                (error) => {
                    this.setState({ error });
                }
            )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.paymentStatus !== this.props.paymentStatus) {
            if(this.props.paymentStatus && this.props.paymentStatus == 'success' && (this.props.placeorderInfo.payment_method == 'online')) {
                document.getElementById("cardPaymentForm").submit();
            }

            if(this.props.paymentStatus && this.props.paymentStatus == 'success' && (this.props.placeorderInfo.payment_method == 'online_bac')) {
                document.getElementById("bacCardPaymentForm").submit();
            }
        }
    }

    handleOnShippingTelInputChange(isValid, newNumber, countryData, fullNumber, isExtension) {
        this.setState({shippingPhone: newNumber });
        this.setState({fullshippingPhone: fullNumber});
    }

    handleOnBillingTelInputChange(isValid, newNumber, countryData, fullNumber, isExtension) {
        this.setState({billingPhone: newNumber });
        this.setState({fullbillingPhone: fullNumber});
    }

    handleOnInputChange(e) {
        const { name, value } = e.target;
        //console.log(name)
        this.setState({[name]: value });
        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleOnShippingInputChange(e) {
        this.setState({
            isShippingAsBillingChecked: !this.state.isShippingAsBillingChecked 
        });
    }

    handleBillingShippingFormValidation(){
        let billingFirstName = this.state.billingFirstName;
        let billingLastName = this.state.billingLastName;
        let billingEmail = this.state.billingEmail;
        let billingAddress1 = this.state.billingAddress1;
        let billingCity = this.state.billingCity;
        let billingState = this.state.billingState;
        //let billingZipCode = this.state.billingZipCode;
        let billingCountry = this.state.billingCountry;
        let billingPhone = this.state.billingPhone;

        let shippingFirstName = this.state.shippingFirstName;
        let shippingLastName = this.state.shippingLastName;
        let shippingAddress1 = this.state.shippingAddress1;
        let shippingCity = this.state.shippingCity;
        let shippingState = this.state.shippingState;
        //let shippingZipCode = this.state.shippingZipCode;
        let shippingCountry = this.state.shippingCountry;
        let shippingPhone = this.state.shippingPhone;

        let errors = {};
        let billingShippingFormIsValid = true;

        if(!billingFirstName){
            billingShippingFormIsValid = false;
            errors["billingFirstName"] = "Por favor, introduzca su nombre de pila.";
        }

        if(!billingLastName){
            billingShippingFormIsValid = false;
            errors["billingLastName"] = "Por favor ingrese su apellido.";
        }
    
        if(!this.state.customerId){
            if(typeof billingEmail !== "undefined"){
                let lastAtPos = billingEmail.lastIndexOf('@');
                let lastDotPos = billingEmail.lastIndexOf('.');
        
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && billingEmail.indexOf('@@') === -1 && lastDotPos > 2 && (billingEmail.length - lastDotPos) > 2)) {
                    billingShippingFormIsValid = false;
                    errors["billingEmail"] = "Su identificación de correo electrónico no es válida.";
                }
            } else{
                billingShippingFormIsValid = false;
                errors["billingEmail"] = "Ingrese su ID de correo electrónico.";
            }
        }

        if(!billingAddress1){
            billingShippingFormIsValid = false;
            errors["billingAddress1"] = "Por favor ingrese su direccion.";
        }

        if(!billingCity){
            billingShippingFormIsValid = false;
            errors["billingCity"] = "Por favor seleccione su corregimiento.";
        }

        if(!billingState){
            billingShippingFormIsValid = false;
            errors["billingState"] = "Por favor seleccione su distrito.";
        }

        // if(!billingZipCode){
        //     billingShippingFormIsValid = false;
        //     errors["billingZipCode"] = "Por favor ingrese su código postal.";
        // }
        
        if(!billingCountry){
            billingShippingFormIsValid = false;
            errors["billingCountry"] = "Por favor seleccione su provincia.";
        }

        if(!billingPhone){
            billingShippingFormIsValid = false;
            errors["billingPhone"] = "Introduzca su número de teléfono.";
        } else{
            if(billingPhone.length < 7){
                billingShippingFormIsValid = false;
                errors["billingPhone"] = "Ingrese su número de teléfono válido.";
            }
        }

        const isShippingSameAsBilling = this.state.isShippingAsBillingChecked;

        if(isShippingSameAsBilling){
            if(!shippingFirstName){
                billingShippingFormIsValid = false;
                errors["shippingFirstName"] = "Por favor, introduzca su nombre de pila.";
            }

            if(!shippingLastName){
                billingShippingFormIsValid = false;
                errors["shippingLastName"] = "Por favor ingrese su apellido.";
            }

            if(!shippingAddress1){
                billingShippingFormIsValid = false;
                errors["shippingAddress1"] = "Por favor ingrese su direccion.";
            }

            if(!shippingCity){
                billingShippingFormIsValid = false;
                errors["shippingCity"] = "Por favor seleccione su corregimiento.";
            }

            if(!shippingState){
                billingShippingFormIsValid = false;
                errors["shippingState"] = "Por favor seleccione su distrito.";
            }
            
            if(!shippingCountry){
                billingShippingFormIsValid = false;
                errors["shippingCountry"] = "Por favor seleccione su provincia.";
            }

            if(!shippingPhone){
                billingShippingFormIsValid = false;
                errors["shippingPhone"] = "Introduzca su número de teléfono.";
            } else{
                if(shippingPhone.length < 7){
                    billingShippingFormIsValid = false;
                    errors["shippingPhone"] = "Ingrese su número de teléfono válido.";
                }
            }
        }

        let deliveryType = this.state.deliveryType;
        
        if(deliveryType > 0){
            let pickupStoreId = this.state.pickupStoreId;
            if(deliveryType === "1" && !pickupStoreId ){
                billingShippingFormIsValid = false;
                errors["deliveryTypeError"] = "Seleccione la dirección de cualquier tienda de recogida.";
            }
            
            let deliveryCost = this.state.deliveryCost;
            if(deliveryType === "2" && !deliveryCost ){
                billingShippingFormIsValid = false;
                errors["deliveryTypeError"] = "Por favor, seleccione el costo de entrega.";
            }
        } else {
            billingShippingFormIsValid = false;
            errors["deliveryTypeError"] = "Seleccione un tipo de entrega.";
        }
    
        this.setState({errors: errors});
        return billingShippingFormIsValid;
    }

    handleOnBillingShippingFormSubmit(e){
        e.preventDefault();
        
        if(this.handleBillingShippingFormValidation()){
            const billingFirstName = this.state.billingFirstName;
            const billingLastName = this.state.billingLastName;
            const billingEmail = this.state.billingEmail;
            const billingCompany = this.state.billingCompany;
            const billingAddress1 = this.state.billingAddress1;
            const billingAddress2 = this.state.billingAddress2;
            const billingCity = this.state.billingCity;
            const billingState = this.state.billingState;
            const billingZipCode = this.state.billingZipCode;
            const billingCountry = this.state.billingCountry;
            const billingPhone = this.state.billingPhone;

            const isShippingSameAsBilling = this.state.isShippingAsBillingChecked;
            const shippingFirstName = this.state.shippingFirstName;
            const shippingLastName = this.state.shippingLastName;
            const shippingEmail = this.state.shippingEmail;
            const shippingAddress1 = this.state.shippingAddress1;
            const shippingAddress2 = this.state.shippingAddress2;
            const shippingCity = this.state.shippingCity;
            const shippingState = this.state.shippingState;
            const shippingZipCode = this.state.shippingZipCode;
            const shippingCountry = this.state.shippingCountry;
            const shippingPhone = this.state.shippingPhone;

            this.setState({ isBillingShippingFormSubmitted: true });

            this.props.addBillingInfo(billingFirstName, billingLastName, billingEmail, billingCompany, billingAddress1, billingAddress2, billingCity, billingState, billingZipCode, billingCountry, billingPhone);
            
            if(!isShippingSameAsBilling){
                this.props.addShippingInfo(isShippingSameAsBilling, shippingFirstName, shippingLastName, shippingEmail, shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingZipCode, shippingCountry, shippingPhone);
            }
        }
    }

    handleOnDeliveryTypeChange = (event) => {
        const deleveryTypeId = event.target.value;
        let errors = {}; 

        if(deleveryTypeId === "1" || deleveryTypeId === "2"){
            this.setState({
                paymentLoader: ''
            });
            
            this.setState({
                deliveryTypeItems: []
            });
            if(deleveryTypeId === "1"){
                var deliveryType = "pickup";
            }else if(deleveryTypeId === "2"){
                deliveryType = "delivery";
            }else{
                deliveryType = "";
            }
            //const apiUrl = 'https://www.evisionstore.com/api/checkout/getdelivery.php';
            const apiUrl = `${API_BASE_URL}/get-delivery`;
            let requestData;

            if(this.state.customerId){
                requestData = {
                    method: 'POST', 
                    //headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify({ 
                        "delivery_type": deliveryType, 
                        "customer_id": this.state.customerId, 
                        "country_id": this.state.billingCountry,
                        "state_id": this.state.billingState,
                        "city_id": this.state.billingCity
                     })
                };
            }else{
                let cartProductsArr = this.state.cartProducts;
                let productsArr = [];
                if (cartProductsArr.length > 0) {
                    for (var q = 0, pricelen = cartProductsArr.length; q < pricelen; q++) {
                        let singleProdId = parseInt(cartProductsArr[q]['prodId']);
                        productsArr.push(singleProdId); 
                    }
                }

                requestData = {
                    method: 'POST', 
                    //headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify({ 
                        "delivery_type": deliveryType, 
                        "product_id": productsArr, 
                        "country_id": this.state.billingCountry,
                        "state_id": this.state.billingState,
                        "city_id": this.state.billingCity
                     })
                };
            }

            Promise.all([
                fetch(apiUrl, requestData)
            ]).then(([res1]) => {
                return Promise.all([res1.json()])
            }).then(([res1]) => {
                this.setState({
                    deliveryTypeItems: res1.data,
                    zoneId: res1.data[0].zone_id,
                    deliveryCost: res1.data[0].delivery_cost,
                    pickupStoreId: ''
                });

                let setDeliveryType = "";
                if(deleveryTypeId === "1") {
                    setDeliveryType = 'Pickup';
                } else if(deleveryTypeId === "2") {
                    setDeliveryType = 'Delivery';
                } else {
                    setDeliveryType = '*';
                }
                const deliveryCost = ((res1.data.length !== 0) ? res1.data[0].delivery_cost : 0);

                if(this.state.customerId){
                    //const apiCheckoutUrl = "https://www.evisionstore.com/api/checkout/getorderreview2.php";
                    const apiCheckoutUrl = `${API_BASE_URL}/cart/review`;

                    let postDeliveryType = "";
                    if(deleveryTypeId === "1") {
                        postDeliveryType = 'pickup';
                    } else if(deleveryTypeId === "2") {
                        postDeliveryType = 'A';
                    } else {
                        postDeliveryType = '';
                    }

                    const requestOptionsForCheckout = {
                        method: 'POST', 
                        headers: { 'Content-Type': 'application/json'},
                        body: JSON.stringify({  
                            "customer_id": this.state.customerId, 
                            "delivery_type": postDeliveryType, 
                            "delivery_amount": deliveryCost
                        })
                    };
            
                    return fetch(apiCheckoutUrl, requestOptionsForCheckout)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                isAllowBacCredomatic: result.data.is_allow_bac_credomatic,
                                cartProductsArray: result.data.order_review,
                                cartProductsDetails: result.data.order_totals[0],
                                cartProductIds: result.data.product_items,
                                paymentLoader: 'hidden'
                            });
                        },
                        (error) => {
                            this.setState({ error, paymentLoader: 'hidden' });
                        }
                    )
                }else{
                    //let cartProductsDetails = [...this.state.cartProductsDetails]; 
                    //cartProductsDetails['delivery_type'] = setDeliveryType; 
                    let deliveryCostAmnt = res1.data[0].delivery_cost !== undefined ? parseFloat(res1.data[0].delivery_cost) : 0;
                    this.setState({
                        paymentLoader: 'hidden',
                        customerDeliveryType: setDeliveryType,
                        deliveryCharge: deliveryCostAmnt,
                        isAllowBacCredomatic: this.state.cartProducts.some(item => item['isAllowedBacCredomatic'] == true),
                    });
                }
            });

        }
        errors["deliveryTypeError"] = "";
        this.setState({ deliveryType: deleveryTypeId, errors: errors });
    };

    handleOnPickupTypeInputChange(pickupStoreId){
        this.setState({
            pickupStoreId: pickupStoreId,
            zoneId: '',
            deliveryCost: ''
        });
    }

    handleOnDeliveryTypeInputChange(zoneId, deliveryCost){
        this.setState({
            zoneId: zoneId,
            deliveryCost: deliveryCost,
            pickupStoreId: ''
        });
    }

    handleBackToPrevious(backToPrevSec){
        if(backToPrevSec==="back_to_billing"){
            this.setState({ isBillingShippingFormSubmitted: false });
        } else if(backToPrevSec==="back_to_shipping") {
            this.setState({ isShippingFormSubmitted: false });
        } else if(backToPrevSec==="back_to_delivery") {
            this.setState({ isBillingShippingFormSubmitted: false });
        }
    }

    fetchCouponApi(couponCode) {
        //let couponApiUrl = "https://www.evisionstore.com/api/checkout/getcoupon.php";
        let couponApiUrl = `${API_BASE_URL}/apply-coupon`;
        return fetch(couponApiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({"customer_id": this.state.customerId, "coupon_code": couponCode})
        })
        
    }

    handleOnCouponSubmit(e){
        e.preventDefault();
        let couponCode = this.state.couponCode;
        let errors = {};
        let responseSuccess = {};

        if(couponCode && couponCode !== ""){
            this.setState({couponLoader: '', couponButtonDisabled: true});
            this.fetchCouponApi(couponCode)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code === 200){
                        responseSuccess["couponCode"] = result.message;
                        const applliedCouponCode = result.data.coupon_code; 
                        const discountAmount = result.data.discount_amount;
                        const discountType = result.data.discount_type;
                        const discountFor = result.data.discount_for;
                        const product_ids = result.data.proids;

                        this.setState({
                            couponLoader: 'hidden', 
                            couponButtonDisabled: false,
                            discountType: discountType, 
                            discountAmount: discountAmount,
                            discountFor: discountFor,
                            couponCode: applliedCouponCode
                        });
                        
                        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getorderreview-react.php';
                        const apiUrl = `${API_BASE_URL}/cart/review`;
                        const postDeliveryType = ((this.state.deliveryType === 2) ? 'A' : 'pickup');
                        const requestOptionsForCheckout = {
                            method: 'POST', 
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({ 
                                "customer_id": this.state.customerId, 
                                "delivery_type": postDeliveryType, 
                                "delivery_amount": this.state.deliveryCost, 
                                "coupon_code": applliedCouponCode, 
                                "coupon_amount": discountAmount, 
                                "discount_type": discountType, 
                                "discount_for": discountFor,
                                "product_ids": product_ids
                            })
                        };
                        return fetch(apiUrl, requestOptionsForCheckout)
                        .then(res => res.json())
                        .then(
                            (result2) => {
                                this.setState({
                                    isAllowBacCredomatic: result2.data.is_allow_bac_credomatic,
                                    cartProductsDetails: result2.data.order_totals[0],
                                    cartProductIds: result2.data.product_items,
                                    cartProductsArray: result2.data.order_review
                                })
                            },
                            (error) => {
                                this.setState({ error });
                            }
                        )
                    }else{
                        errors["couponCode"] = result.message;
                        this.setState({
                            couponLoader: 'hidden', 
                            couponButtonDisabled: false,
                            errors: errors
                        });
                    }
                },
                (error) => {
                    this.setState({ error, couponButtonDisabled: false });
                }
            )
        }else{
            errors["couponCode"] = "Please enter promo code here.";
        }

        this.setState({ responseSuccess: responseSuccess, errors: errors });
    }

    handleOnCountryChange = (event) => {
        this.setState({paymentLoader: ''});
        const country_id = event.target.value;
        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcitybycountry.php';
        const apiUrl = `${API_BASE_URL}/city-by-country`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"country": country_id})
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    cityItems: result.data,
                    paymentLoader: 'hidden'
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['billingCountry'] = "";
        this.setState({errors: errors });
        this.setState({ billingCountry: country_id });
    };

    handleOnStateChange = (event) => {
        this.setState({paymentLoader: ''});
        const state_id = event.target.value;
        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcity.php';
        const apiUrl = `${API_BASE_URL}/city-by-state`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"state": state_id})
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    cityItems: result.data,
                    paymentLoader: 'hidden'
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['billingState'] = "";
        this.setState({errors: errors });
        this.setState({ billingState: state_id });
    };

    handleOnCityChange = (event) => {
        this.setState({paymentLoader: ''});
        const city_id = event.target.value;
        this.setState({ billingCity: city_id });

        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getstatebycity.php';
        const apiUrl = `${API_BASE_URL}/state-by-city`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                "city": city_id
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({ billingState: result.data.state_id });
            }
        )
        
        let deliveryType = this.state.deliveryType;
        if(this.state.deliveryType) {
            if(deliveryType === "1"){
                deliveryType = "pickup";
            }else if(deliveryType === "2"){
                deliveryType = "delivery";
            }else{
                deliveryType = "";
            }
            //const apiUrl = 'https://www.evisionstore.com/api/checkout/getdelivery.php';
            const apiUrl = `${API_BASE_URL}/get-delivery`;
            let requestData;
            
            if(this.state.customerId){
                requestData = {
                    method: 'POST', 
                    //headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify({ 
                        "delivery_type": deliveryType, 
                        "customer_id": this.state.customerId, 
                        "country_id": this.state.billingCountry,
                        "state_id": this.state.billingState,
                        "city_id": city_id
                     })
                };
            }else{
                let cartProductsArr = this.state.cartProducts;
                let productsArr = [];
                if (cartProductsArr.length > 0) {
                    for (var q = 0, pricelen = cartProductsArr.length; q < pricelen; q++) {
                        let singleProdId = parseInt(cartProductsArr[q]['prodId']);
                        productsArr.push(singleProdId); 
                    }
                }

                requestData = {
                    method: 'POST', 
                    //headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify({ 
                        "delivery_type": deliveryType, 
                        "product_id": productsArr, 
                        "country_id": this.state.billingCountry,
                        "state_id": this.state.billingState,
                        "city_id": city_id
                     })
                };
            }

            fetch(apiUrl, requestData)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        deliveryTypeItems: result.data
                    });

                    if(this.state.customerId){
                        const requestOptionsForCheckout = {
                            method: 'POST', 
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({  
                                "customer_id": this.state.customerId, 
                                "delivery_type": deliveryType, 
                                "delivery_amount": result.data[0].delivery_cost
                            })
                        };

                        //const apiCheckoutUrl = "https://www.evisionstore.com/api/checkout/getorderreview2.php";
                        const apiCheckoutUrl = `${API_BASE_URL}/cart/review`;
                        return fetch(apiCheckoutUrl, requestOptionsForCheckout)
                        .then(rest => rest.json())
                        .then(
                            (result2) => {
                                if(result2.code === 200){
                                    this.setState({
                                        isAllowBacCredomatic: result2.data.is_allow_bac_credomatic,
                                        cartProductsArray: result2.data.order_review,
                                        cartProductsDetails: result2.data.order_totals[0],
                                        cartProductIds: result2.data.product_items,
                                        paymentLoader: 'hidden'
                                    })
                                }else{
                                    this.setState({
                                        isProductsAddedToCart: false,
                                        paymentLoader: 'hidden'
                                    });
                                }
                            }
                        )
                    }else{
                        let deliveryCostAmnt = result.data[0].delivery_cost !== undefined ? parseFloat(result.data[0].delivery_cost) : 0;
                        this.setState({
                            paymentLoader: 'hidden',
                            deliveryCharge: deliveryCostAmnt
                        });
                    }
                },
                (error) => {
                    this.setState({ error });
                }
            )
        }else{
            this.setState({
                paymentLoader: 'hidden'
            });
        }

        let errors = this.state.errors;
        errors['billingCity'] = "";
        this.setState({errors: errors });
    };

    handleOnShippingCountryChange = (event) => {
        const country_id = event.target.value;
        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcitybycountry.php';
        const apiUrl = `${API_BASE_URL}/city-by-country`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"country": country_id})
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    shippingCityItems: result.data
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['shippingCountry'] = "";
        this.setState({errors: errors });
        this.setState({ shippingCountry: country_id });
    };

    handleOnShippingStateChange = (event) => {
        const state_id = event.target.value;
        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getcity.php';
        const apiUrl = `${API_BASE_URL}/city-by-state`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({"state_id": state_id})
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    shippingCityItems: result.data
                });
            },
            (error) => {
                this.setState({ error });
            }
        )
        
        let errors = this.state.errors;
        errors['shippingState'] = "";
        this.setState({errors: errors });
        this.setState({ shippingState: state_id });
    };

    handleOnShippingCityChange = (event) => {
        const city_id = event.target.value;
        this.setState({ shippingCity: city_id });

        //const apiUrl = 'https://www.evisionstore.com/api/checkout/getstatebycity.php';
        const apiUrl = `${API_BASE_URL}/state-by-city`;

        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                "city": city_id
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({ shippingState: result.data.state_id });
            }
        )

        let errors = this.state.errors;
        errors['shippingCity'] = "";
        this.setState({errors: errors });
    };

    handleOnMonthInputChange = (event) => {
        const expMonthId = event.target.value;
        this.setState({ cardExpMonth: expMonthId });

        //const cardExpMonthYearValue = expMonthId + '/' + this.state.cardExpYear;
        const cardExpMonthYearValue = expMonthId + this.state.cardExpYear.slice(-2);
        this.setState({cardExpMonthYear: cardExpMonthYearValue});

        let errors = this.state.errors;
        errors['cardExpMonth'] = "";
        this.setState({errors: errors });
    };

    handleOnYearInputChange = (event) => {
        const expYearId = event.target.value;
        this.setState({ cardExpYear: expYearId });

        //const cardExpMonthYearValue = this.state.cardExpMonth + '/' + expYearId;
        const cardExpMonthYearValue = this.state.cardExpMonth + expYearId.slice(-2);
        this.setState({cardExpMonthYear: cardExpMonthYearValue});

        let errors = this.state.errors;
        errors['cardExpYear'] = "";
        this.setState({errors: errors });
    };

    handleOnMonthInputChangeBac = (event) => {
        const expMonthId = event.target.value;
        this.setState({ bacCardExpMonth: expMonthId });

        const cardExpMonthYearValue = expMonthId + this.state.bacCardExpYear.slice(-2);
        this.setState({bacCardExpMonthYear: cardExpMonthYearValue});

        let errors = this.state.errors;
        errors['cardExpMonth'] = "";
        this.setState({errors: errors });
    };

    handleOnYearInputChangeBac = (event) => {
        const expYearId = event.target.value;
        this.setState({ bacCardExpYear: expYearId });

        const cardExpMonthYearValue = this.state.bacCardExpMonth + expYearId.slice(-2);
        this.setState({bacCardExpMonthYear: cardExpMonthYearValue});

        let errors = this.state.errors;
        errors['cardExpYear'] = "";
        this.setState({errors: errors });
    };

    handleCardPaymentFormValidation(){
        let cardNumber = this.state.ccnumber;
        let cardExpMonth = this.state.cardExpMonth;
        let cardExpYear = this.state.cardExpYear;
        let cardCvv = this.state.cvv;
        let cardName = this.state.cardName;
        let isCredTermsAndConditionChecked = this.state.isCredTermsAndConditionChecked;

        let errors = {};
        let paymentFormIsValid = true;

        if(!cardNumber){
            paymentFormIsValid = false;
            errors["cardNumber"] = "Ingrese su número de tarjeta.";
        }else {
            if(cardNumber.length !== 16){
                paymentFormIsValid = false;
                errors["cardNumber"] = "Ingrese su número de tarjeta válido.";
            }
        }

        if(!cardExpMonth){
            paymentFormIsValid = false;
            errors["cardExpMonth"] = "Ingrese el mes de vencimiento.";
        }

        if(!cardExpYear){
            paymentFormIsValid = false;
            errors["cardExpYear"] = "Ingrese el año de vencimiento.";
        }

        if(!cardCvv){
            paymentFormIsValid = false;
            errors["cardCvv"] = "Ingrese su CVV.";
        }else {
            if(cardCvv.length < 3){
                paymentFormIsValid = false;
                errors["cardCvv"] = "Ingrese su CVV válido.";
            }
        }

        if(!cardName){
            paymentFormIsValid = false;
            errors["cardName"] = "Ingrese el nombre de su tarjeta.";
        }

        if(!isCredTermsAndConditionChecked){
            paymentFormIsValid = false;
            errors["credTermsAndConditionError"] = "Acepte todos los términos y condiciones antes de realizar el pedido.";
        }

        this.setState({errors: errors});
        return paymentFormIsValid;
    }

    handleOnCardPaymentFormSubmit(e){
        e.preventDefault();
        
        if(this.handleCardPaymentFormValidation()){
            const cardNumber = this.state.ccnumber;
            // const cardExpMonth = this.state.cardExpMonth;
            // const cardExpYear = this.state.cardExpYear;
            // const cardCvv = this.state.cvv;
            // const cardName = this.state.cardName;
            this.setState({ isCardPaymentFormSubmitted: true, credButtonDisabled: true, paymentLoader: '' });

            let customerId = this.props.customerId;

            let cardInfoObj = {
                'cardNumber': cardNumber
            }

            let billingInfoObj = this.props.billingInfo;
            let shippingInfoObj = this.props.shippingInfo;
            let desliveryInfoObj = {
                deliveryType: this.state.deliveryType,
                zoneId: this.state.zoneId,
                deliveryCost: this.state.deliveryCost,
                pickupStoreId: this.state.pickupStoreId
            }

            let couponInfoObj = {
                discountType: this.state.discountType,
                discountAmount: this.state.discountAmount,
                discountFor: this.state.discountFor,
                cartProductIds: this.state.cartProductIds 
            }

            //let cartAttributesObj = this.props.cartAttributes;
            let clientIpAddress = this.state.clientIpAddress;
            let cartProductsArr = [];
            let isGuestCheckoutSubmited;

            if(this.props.customerId){
                isGuestCheckoutSubmited = false;
                this.props.submitCardPaymentForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, cardInfoObj, couponInfoObj, clientIpAddress);
            }else{
                cartProductsArr = this.state.cartProducts;
                isGuestCheckoutSubmited = true;

                this.props.submitCardPaymentForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, cardInfoObj, couponInfoObj, clientIpAddress);
            }
        }
    }

    handleBacCardPaymentFormValidation(){
        let cardNumber = this.state.ccnumber;
        let cardExpMonth = this.state.bacCardExpMonth;
        let cardExpYear = this.state.bacCardExpYear;
        let cardCvv = this.state.cvv;
        let cardName = this.state.bacCardName;
        let isBacCredTermsAndConditionChecked = this.state.isBacCredTermsAndConditionChecked;
        
        let errors = {};
        let paymentFormIsValid = true;

        if(!cardNumber){
            paymentFormIsValid = false;
            errors["bacCardNumber"] = "Ingrese su número de tarjeta.";
        }else {
            if(cardNumber.length !== 16){
                paymentFormIsValid = false;
                errors["bacCardNumber"] = "Ingrese su número de tarjeta válido.";
            }
        }

        if(!cardExpMonth){
            paymentFormIsValid = false;
            errors["bacCardExpMonth"] = "Ingrese el mes de vencimiento.";
        }

        if(!cardExpYear){
            paymentFormIsValid = false;
            errors["bacCardExpYear"] = "Ingrese el año de vencimiento.";
        }

        if(!cardCvv){
            paymentFormIsValid = false;
            errors["bacCardCvv"] = "Ingrese su CVV.";
        }else {
            if(cardCvv.length < 3){
                paymentFormIsValid = false;
                errors["bacCardCvv"] = "Ingrese su CVV válido.";
            }
        }

        if(!cardName){
            paymentFormIsValid = false;
            errors["bacCardName"] = "Ingrese el nombre de su tarjeta.";
        }

        if(!isBacCredTermsAndConditionChecked){
            paymentFormIsValid = false;
            errors["bacCredTermsAndConditionError"] = "Acepte todos los términos y condiciones antes de realizar el pedido.";
        }

        this.setState({errors: errors});
        return paymentFormIsValid;
    }

    handleOnBacCardPaymentFormSubmit(e){
        e.preventDefault();
        
        if(this.handleBacCardPaymentFormValidation()){
            const cardNumber = this.state.ccnumber;
            // const cardExpMonth = this.state.bacCardExpMonth;
            // const cardExpYear = this.state.bacCardExpYear;
            // const cardCvv = this.state.bacCardCvv;
            // const cardName = this.state.bacCardName;
            this.setState({ isCardPaymentFormSubmitted: true, bacCredButtonDisabled: true, paymentLoader: '' });

            let customerId = this.props.customerId;

            let cardInfoObj = {
                'cardNumber': cardNumber
            }

            let billingInfoObj = this.props.billingInfo;
            let shippingInfoObj = this.props.shippingInfo;
            let desliveryInfoObj = {
                deliveryType: this.state.deliveryType,
                zoneId: this.state.zoneId,
                deliveryCost: this.state.deliveryCost,
                pickupStoreId: this.state.pickupStoreId
            }

            let couponInfoObj = {
                discountType: this.state.discountType,
                discountAmount: this.state.discountAmount,
                discountFor: this.state.discountFor,
                cartProductIds: this.state.cartProductIds 
            }

            let cartAttributesObj = this.props.cartAttributes;
            let clientIpAddress = this.state.clientIpAddress;
            let cartProductsArr = [];
            let isGuestCheckoutSubmited;

            if(this.props.customerId){
                isGuestCheckoutSubmited = false;
                this.props.submitBacCardPaymentForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, cardInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }else{
                cartProductsArr = this.state.cartProducts;
                isGuestCheckoutSubmited = true;
                this.props.submitBacCardPaymentForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, cardInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }
        }
    }

    handleOnBankTransferFormSubmit(e) {
        e.preventDefault();
        
        this.setState({ isCardPaymentFormSubmitted: true });

        let customerId = this.props.customerId;

        let billingInfoObj = this.props.billingInfo;
        let shippingInfoObj = this.props.shippingInfo;
        let desliveryInfoObj = {
            deliveryType: this.state.deliveryType,
            zoneId: this.state.zoneId,
            deliveryCost: this.state.deliveryCost,
            pickupStoreId: this.state.pickupStoreId
        }

        let couponInfoObj = {
            discountType: this.state.discountType,
            discountAmount: this.state.discountAmount,
            discountFor: this.state.discountFor,
            cartProductIds: this.state.cartProductIds 
        }

        let bankFormIsValid = true;
        let errors = {};

        if(!this.state.isBankTermsAndConditionChecked){
            bankFormIsValid = false;
            errors["bankTermsAndConditionError"] = "Acepte todos los términos y condiciones antes de realizar el pedido.";
        }
        
        if(bankFormIsValid){
            this.setState({ bankButtonDisabled: true, paymentLoader: '' });
            //let cartAttributesObj = this.props.cartAttributes;
            let clientIpAddress = this.state.clientIpAddress;
            let cartProductsArr = [];
            let isGuestCheckoutSubmited;

            if(this.props.customerId){
                isGuestCheckoutSubmited = false;
                this.props.submitBankTransferForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, clientIpAddress);
            }else{
                cartProductsArr = this.state.cartProducts;
                isGuestCheckoutSubmited = true;

                this.props.submitBankTransferForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, clientIpAddress);
            }
        } else {
            this.setState({errors: errors});
        }
        
    }

    handleOnYappyFormSubmit(e) {
        e.preventDefault();
        
        this.setState({ isCardPaymentFormSubmitted: true });

        let customerId = this.props.customerId;

        let billingInfoObj = this.props.billingInfo;
        let shippingInfoObj = this.props.shippingInfo;
        let desliveryInfoObj = {
            deliveryType: this.state.deliveryType,
            zoneId: this.state.zoneId,
            deliveryCost: this.state.deliveryCost,
            pickupStoreId: this.state.pickupStoreId
        }

        let couponInfoObj = {
            discountType: this.state.discountType,
            discountAmount: this.state.discountAmount,
            discountFor: this.state.discountFor,
            cartProductIds: this.state.cartProductIds 
         }

        let yappyFormIsValid = true;
        let errors = {};

        if(!this.state.isYappyTermsAndConditionChecked){
            yappyFormIsValid = false;
            errors["yappyTermsAndConditionError"] = "Acepte todos los términos y condiciones antes de realizar el pedido.";
        }
        
        if(yappyFormIsValid){
            this.setState({ yappyButtonDisabled: true, paymentLoader: '' });
            let cartAttributesObj = this.props.cartAttributes;
            let clientIpAddress = this.state.clientIpAddress;
            let cartProductsArr = [];
            let isGuestCheckoutSubmited;

            if(this.props.customerId){
                isGuestCheckoutSubmited = false;
                this.props.submitYappyForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }else{
                cartProductsArr = this.state.cartProducts;
                isGuestCheckoutSubmited = true;

                this.props.submitYappyForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }
        } else {
            this.setState({errors: errors});
        }
    }

    handleOnClaveFormSubmit(e) {
        e.preventDefault();
        
        this.setState({ isCardPaymentFormSubmitted: true });

        let customerId = this.props.customerId;

        let billingInfoObj = this.props.billingInfo;
        let shippingInfoObj = this.props.shippingInfo;
        let desliveryInfoObj = {
            deliveryType: this.state.deliveryType,
            zoneId: this.state.zoneId,
            deliveryCost: this.state.deliveryCost,
            pickupStoreId: this.state.pickupStoreId
        }

        let couponInfoObj = {
           discountType: this.state.discountType,
           discountAmount: this.state.discountAmount,
           discountFor: this.state.discountFor,
           cartProductIds: this.state.cartProductIds 
        }

        let claveFormIsValid = true;
        let errors = {};

        if(!this.state.isClaveTermsAndConditionChecked){
            claveFormIsValid = false;
            errors["claveTermsAndConditionError"] = "Acepte todos los términos y condiciones antes de realizar el pedido.";
        }
        
        if(claveFormIsValid){
            this.setState({ claveButtonDisabled: true, paymentLoader: '' });
            let cartAttributesObj = this.props.cartAttributes;
            let clientIpAddress = this.state.clientIpAddress;
            let cartProductsArr = [];
            let isGuestCheckoutSubmited;

            if(this.props.customerId){
                isGuestCheckoutSubmited = false;
                this.props.submitClaveForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }else{
                cartProductsArr = this.state.cartProducts;
                isGuestCheckoutSubmited = true;

                this.props.submitClaveForm(customerId, cartProductsArr, isGuestCheckoutSubmited, billingInfoObj, shippingInfoObj, desliveryInfoObj, couponInfoObj, cartAttributesObj, clientIpAddress);
            }
        } else {
            this.setState({errors: errors});
        }
        
        
    }

    handleOnAcceptTermsAndConditionInputChangeForCred(e) {
        let errors = this.state.errors;
        errors['credTermsAndConditionError'] = "";

        this.setState({
            isCredTermsAndConditionChecked: !this.state.isCredTermsAndConditionChecked,
            errors: errors
        });
    }

    handleOnAcceptTermsAndConditionInputChangeForBakCred(e) {
        let errors = this.state.errors;
        errors['bacCredTermsAndConditionError'] = "";

        this.setState({
            isBacCredTermsAndConditionChecked: !this.state.isBacCredTermsAndConditionChecked,
            errors: errors 
        });
    }

    handleOnAcceptTermsAndConditionInputChangeForBank(e) {
        let errors = this.state.errors;
        errors['bankTermsAndConditionError'] = "";

        this.setState({
            isBankTermsAndConditionChecked: !this.state.isBankTermsAndConditionChecked,
            errors: errors 
        });
    }

    handleOnAcceptTermsAndConditionInputChangeForYappy(e) {
        let errors = this.state.errors;
        errors['yappyTermsAndConditionError'] = "";

        this.setState({
            isYappyTermsAndConditionChecked: !this.state.isYappyTermsAndConditionChecked,
            errors: errors 
        });
    }

    handleOnAcceptTermsAndConditionInputChangeForClave(e) {
        let errors = this.state.errors;
        errors['claveTermsAndConditionError'] = "";

        this.setState({
            isClaveTermsAndConditionChecked: !this.state.isClaveTermsAndConditionChecked,
            errors: errors 
        });
    }

    termsAndCOnditionsPopupOpen() {
        this.setState({showTermsAndConditionsModal: true});
    }
    
    termsAndCOnditionsPopupClose() {
        this.setState({showTermsAndConditionsModal: false});
    }

    privacyPolicyPopupOpen() {
        this.setState({showPrivacyPolicyModal: true});
    }
    
    privacyPolicyPopupClose() {
        this.setState({showPrivacyPolicyModal: false});
    }

    financial = (x) => Number.parseFloat(x).toFixed(2);

    onMarkerClick = (props, marker, e) =>
    this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
    });
        
    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
            showingInfoWindow: false,
            activeMarker: null
            });
        }
    };

    render() {
        const {isLoggedIn, customerId} = this.props;
        const { countryItems, stateItems, shippingStateItems, cityItems, shippingCityItems } = this.state;
        const { billingFirstName, billingLastName, billingEmail, billingCompany, billingAddress1, billingAddress2, billingCountry, billingState, billingCity, billingZipCode, billingAddInfo, billingPhone, billingAddressTitle, isBillingShippingFormSubmitted } = this.state;
        const { shippingFirstName, shippingLastName, shippingEmail, shippingAddress1, shippingAddress2, shippingCountry, shippingState, shippingCity, shippingZipCode, shippingPhone, isShippingFormSubmitted } = this.state;
        const { isDeliveryFormSubmitted, deliveryType, deliveryTypeItems, pickupStoreId, zoneId, deliveryCost } = this.state;
        const { ccnumber, cardExpMonth, cardExpYear, cvv, cardName, couponCode } = this.state;
        const { bacCardNumber, bacCardExpMonth, bacCardExpYear, bacCardCvv, bacCardName } = this.state;
        let { deliveryCharge } = this.state;
        
        const cartProducts = this.props.cartProducts;
        const paymentStatus = this.props.paymentStatus;
        const placeorderInfo = this.props.placeorderInfo;
 
        if (paymentStatus) {
            let paymentMethod = placeorderInfo.payment_method;

            if(paymentMethod === "bank_transfer"){
                return <Redirect to={`/success`} />;
            }
        }

        if(this.props.cartCount === 0){
            return <Redirect to={`/`} />;
        }

        if(!this.state.isProductsAddedToCart){
            return <Redirect to={`/`} />;
        }

        const hidden = this.state.isShippingAsBillingChecked ? '' : 'hidden';
        const pickupShowHidden = ((deliveryType === "1") ? '' : 'hidden');
        const deliveryShowHidden = ((deliveryType === "2") ? '' : 'hidden');

        let currencyFormat = '$';
        let totalProductPrice = 0;
        let taxPercentage = 'ITBMS (TAX 7%)';
        let totalTaxAmount = 0;
        let discountAmount = 0;
        let customerDeliveryType;
        let totalAmount = 0;

        if(!isLoggedIn){
            if (cartProducts.length > 0) {
                for (var q = 0, pricelen = cartProducts.length; q < pricelen; q++) {
                    let singleProdPrice = parseFloat(cartProducts[q]['prodPrice']);
                    let singleProdQuan = parseInt(cartProducts[q]['prodQuantity']);
                    totalProductPrice = (totalProductPrice + (singleProdPrice * singleProdQuan)); 
                }

                if (totalProductPrice > 0) {
                    totalTaxAmount = (totalProductPrice * (7 / 100));
                }

                totalAmount = ((totalAmount + totalProductPrice + totalTaxAmount + deliveryCharge) - discountAmount);
            }
            
            customerDeliveryType = this.state.customerDeliveryType;
        }else{
            currencyFormat = this.state.cartProductsDetails.currency;
            totalProductPrice = this.state.cartProductsDetails.subtotal;
            taxPercentage = this.state.cartProductsDetails.tax_name;
            totalTaxAmount = this.state.cartProductsDetails.tax;
            discountAmount = this.state.cartProductsDetails.discount_amount;
            deliveryCharge = this.state.cartProductsDetails.delivery;
            customerDeliveryType = this.state.cartProductsDetails.delivery_type;
            totalAmount = this.state.cartProductsDetails.grand_total;
        }

        const yearOptions = [];

        for (let i = new Date().getFullYear(); i <= (new Date().getFullYear() + 10); i++) {
            yearOptions.push(<option key={i} value={i}>{i}</option>);
        }

        var showBacCredomatic = (
            <div></div>
        )

        if(this.state.isAllowBacCredomatic){
            showBacCredomatic = (
                <Card className="payment-card" style={{cursor:'default'}}>
                    <Card.Header className="px-0 py-3">
                        <Accordion.Toggle eventKey="4" className="toggle_btn">
                            <input type="radio" name="paymentMethod" className="payment_radio_btn" value="credit_card_bac" />
                        </Accordion.Toggle>
                        &nbsp; <strong className="payment_title_txt">Tasa 0% de BAC Credomatic</strong>
                        <img className="card_img" src="https://www.evisionstore.com/images/bac-credomatic.jpg" alt=""/>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                        
                            <Form id="bacCardPaymentForm" method="post" onSubmit={this.handleOnBacCardPaymentFormSubmit} action="https://credomatic.compassmerchantsolutions.com/api/transact.php">
                                <Form.Control type="hidden" name="type" value={placeorderInfo.type} />
                                <Form.Control type="hidden" name="key_id" value={placeorderInfo.key_id} />
                                <Form.Control type="hidden" name="hash" value={placeorderInfo.hash} />
                                <Form.Control type="hidden" name="time" value={placeorderInfo.order_time} />
                                <Form.Control type="hidden" name="amount" value={placeorderInfo.order_amount} />
                                <Form.Control type="hidden" name="orderid" value={placeorderInfo.order_id} />
                                <Form.Control type="hidden" name="processor_id" value={placeorderInfo.processor_id} />
                                <Form.Control type="hidden" name="redirect" value={placeorderInfo.return_url} />
                                <Form.Control type="hidden" name="ccnumber" value={this.state.ccnumber} />
                                <Form.Control type="hidden" name="ccexp" value={this.state.bacCardExpMonthYear} />
                                <div className="col-xs-12 col-sm-12">
                                    <p>Todas las transacciones son seguras y encriptadas, y nunca almacenamos la información de su tarjeta de crédito / débito. Para obtener más información, consulte nuestra política de privacidad.</p>
                                </div>
                                <div className="creditCard">
                                    <div className="col-xs-12">
                                        <Form.Group controlId="formGroupBacCardNumber">
                                            {/* <Form.Label>Número de tarjeta *</Form.Label> */}
                                            <Form.Control name="ccnumber" value={ccnumber} onChange={this.handleOnInputChange} placeholder="Card Number" className="form-control" maxLength="16"/>
                                            <span style={{color: "red"}}>{this.state.errors["bacCardNumber"]}</span>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Group controlId="formGroupBacCardMonth">
                                            {/* <Form.Label>Expiración Meses *</Form.Label> */}
                                            <Form.Control as="select" name="bacCardExpMonth" value={bacCardExpMonth} onChange={this.handleOnMonthInputChangeBac} className="form-control">
                                                <option>Meses</option>
                                                <option value="01">01 - January</option>
                                                <option value="02">02 - February</option>
                                                <option value="03">03 - March</option>
                                                <option value="04">04 - April</option>
                                                <option value="05">05 - May</option>
                                                <option value="06">06 - June</option>
                                                <option value="07">07 - July</option>
                                                <option value="08">08 - August</option>
                                                <option value="09">09 - September</option>
                                                <option value="10">10 - October</option>
                                                <option value="11">11 - November</option>
                                                <option value="12">12 - December</option>
                                            </Form.Control>
                                            <span style={{color: "red"}}>{this.state.errors["bacCardExpMonth"]}</span>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Group controlId="formGroupBacCardYear">
                                            {/* <Form.Label>Año de vencimiento *</Form.Label> */}
                                            <Form.Control as="select" name="bacCardExpYear" value={bacCardExpYear} onChange={this.handleOnYearInputChangeBac} className="form-control">
                                                <option>Año</option>
                                                {yearOptions}
                                            </Form.Control>
                                            <span style={{color: "red"}}>{this.state.errors["bacCardExpYear"]}</span>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Group controlId="formGroupBacCardCVV">
                                            {/* <Form.Label>CVV *</Form.Label> */}
                                            <Form.Control type="password" name="cvv" value={cvv} onChange={this.handleOnInputChange} placeholder="CVV" className="form-control" maxLength="4" />
                                            <span style={{color: "red"}}>{this.state.errors["bacCardCvv"]}</span>
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-sm-6"><img src="https://www.evisionstore.com/images/payment.png" style={{position: 'relative', top: '22px'}} width="120px" height="40px" alt=""></img></div> */}
                                    <div className="col-sm-6">
                                        <Form.Group controlId="formGroupBacCardName">
                                            {/* <Form.Label>Nombre en la tarjeta *</Form.Label> */}
                                            <Form.Control name="bacCardName" value={bacCardName} onChange={this.handleOnInputChange} placeholder="Nombre completo" className="form-control"/>
                                            <span style={{color: "red"}}>{this.state.errors["bacCardName"]}</span>
                                        </Form.Group>
                                    </div>
                                    <div style={{clear: 'both'}} />
                                </div>
                                <p className="col-xs-12 d-flex align-items-center term-condition-line">
                                    <input onChange={this.handleOnAcceptTermsAndConditionInputChangeForBakCred} checked={ this.state.isBacCredTermsAndConditionChecked } name="isBacCredTermsAndCondition" type="checkbox" />
                                    &nbsp;Acepto los <Link to="#" onClick={this.termsAndCOnditionsPopupOpen}>terminos y condiciones de venta</Link> y <Link to="#" onClick={this.privacyPolicyPopupOpen}>Políticas de Privacidad</Link>
                                    <br/>
                                </p>
                                <span style={{color:'red'}}>{this.state.errors["bacCredTermsAndConditionError"]}</span>
                                <div className="blog-buttton">
                                    <Button type="submit" className="btn btn-primary btn-small" disabled={this.state.bacCredButtonDisabled}>
                                        Realizar pedido
                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                    </Button>
                                </div>
                                <div style={{clear: 'both'}} />
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
        }

        if(isBillingShippingFormSubmitted){
            return (
                <>
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-center p-0 mb-4">
                                <div className="px-0 pb-0 mt-3">
                                    <form id="form">
                                        <ul id="progressbar">
                                            <li className="active" id="step1">
                                                <strong>Datos Personales</strong>
                                            </li>
                                            <li className="active" id="step2"><strong>Información de Pago</strong></li>
                                            <li id="step3"><strong>Confirmación</strong></li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12 slide-animation4">
                                <div className="row userInfo">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="w100 clearfix">
                                            <div className="row userInfo">
                                                {/* <div className="col-lg-12">
                                                    <h4 className="block-title-2"> Método de Pago </h4>
                                                    <p>Seleccione su método de pago preferido para utilizar en este pedido.</p>
                                                </div> */}
                                                <div className="col-xs-12 col-sm-12">
                                                    <div className="paymentBox">
                                                        <Accordion defaultActiveKey="0">
                                                            <Card className="payment-card" style={{cursor:'default'}}>
                                                                <Card.Header eventKey="0" className="px-0 py-3">
                                                                    <Accordion.Toggle eventKey="0" className="toggle_btn">
                                                                        <input type="radio" name="paymentMethod" className="payment_radio_btn" value="credit_card" defaultChecked />
                                                                    </Accordion.Toggle>
                                                                    &nbsp;<strong className="payment_title_txt">Tarjeta de crédito / débito</strong>
                                                                    <img className="card_img" src="https://www.evisionstore.com/images/payment.png" alt=""/>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="0">
                                                                    <Card.Body>
                                                                        <Form id="cardPaymentForm" method="post" onSubmit={this.handleOnCardPaymentFormSubmit} action="https://credomatic.compassmerchantsolutions.com/api/transact.php">
                                                                            <Form.Control type="hidden" name="type" value={placeorderInfo.type} />
                                                                            <Form.Control type="hidden" name="key_id" value={placeorderInfo.key_id} />
                                                                            <Form.Control type="hidden" name="hash" value={placeorderInfo.hash} />
                                                                            <Form.Control type="hidden" name="time" value={placeorderInfo.order_time} />
                                                                            <Form.Control type="hidden" name="amount" value={placeorderInfo.order_amount} />
                                                                            <Form.Control type="hidden" name="orderid" value={placeorderInfo.order_id} />
                                                                            <Form.Control type="hidden" name="processor_id" value={placeorderInfo.processor_id} />
                                                                            <Form.Control type="hidden" name="redirect" value={placeorderInfo.return_url} />
                                                                            <Form.Control type="hidden" name="ccexp" value={this.state.cardExpMonthYear} />
                                                                            
                                                                            <div className="col-xs-12 col-sm-12">
                                                                                <p>Todas las transacciones son seguras y encriptadas, y nunca almacenamos la información de su tarjeta de crédito / débito. Para obtener más información, consulte nuestra política de privacidad.</p>
                                                                            </div>
                                                                            <div className="creditCard">
                                                                                <div className="col-xs-12">
                                                                                <Form.Group controlId="formGroupCardNumber">
                                                                                    {/* <Form.Label>Número de tarjeta *</Form.Label> */}
                                                                                    <Form.Control name="ccnumber" value={ccnumber} onChange={this.handleOnInputChange} placeholder="Card Number" className="form-control" maxLength="16"/>
                                                                                    <span style={{color: "red"}}>{this.state.errors["cardNumber"]}</span>
                                                                                </Form.Group>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                <Form.Group controlId="formGroupCardMonth">
                                                                                    {/* <Form.Label>Expiración Meses *</Form.Label> */}
                                                                                    <Form.Control as="select" name="cardExpMonth" value={cardExpMonth} onChange={this.handleOnMonthInputChange} className="form-control">
                                                                                        <option>Meses</option>
                                                                                        <option value="01">01 - January</option>
                                                                                        <option value="02">02 - February</option>
                                                                                        <option value="03">03 - March</option>
                                                                                        <option value="04">04 - April</option>
                                                                                        <option value="05">05 - May</option>
                                                                                        <option value="06">06 - June</option>
                                                                                        <option value="07">07 - July</option>
                                                                                        <option value="08">08 - August</option>
                                                                                        <option value="09">09 - September</option>
                                                                                        <option value="10">10 - October</option>
                                                                                        <option value="11">11 - November</option>
                                                                                        <option value="12">12 - December</option>
                                                                                    </Form.Control>
                                                                                    <span style={{color: "red"}}>{this.state.errors["cardExpMonth"]}</span>
                                                                                </Form.Group>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                <Form.Group controlId="formGroupCardYear">
                                                                                    {/* <Form.Label>Año de vencimiento *</Form.Label> */}
                                                                                    <Form.Control as="select" name="cardExpYear" value={cardExpYear} onChange={this.handleOnYearInputChange} className="form-control">
                                                                                        <option>Año</option>
                                                                                        {yearOptions}
                                                                                    </Form.Control>
                                                                                    <span style={{color: "red"}}>{this.state.errors["cardExpYear"]}</span>
                                                                                </Form.Group>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                <Form.Group controlId="formGroupCardCVV">
                                                                                    {/* <Form.Label>CVV *</Form.Label> */}
                                                                                    <Form.Control type="password" name="cvv" value={cvv} onChange={this.handleOnInputChange} placeholder="CVV" className="form-control" maxLength="4" />
                                                                                    <span style={{color: "red"}}>{this.state.errors["cardCvv"]}</span>
                                                                                </Form.Group>
                                                                                </div>
                                                                                {/* <div className="col-sm-6 accept-cards"><img src="https://www.evisionstore.com/images/payment.png" style={{position: 'relative', top: '22px'}} width="120px" height="40px" alt=""></img></div> */}
                                                                                <div className="col-sm-6">
                                                                                <Form.Group controlId="formGroupCardName">
                                                                                    {/* <Form.Label>Nombre en la tarjeta *</Form.Label> */}
                                                                                    <Form.Control name="cardName" value={cardName} onChange={this.handleOnInputChange} placeholder="Nombre completo" className="form-control"/>
                                                                                    <span style={{color: "red"}}>{this.state.errors["cardName"]}</span>
                                                                                </Form.Group>
                                                                                </div>

                                                                                <div style={{clear: 'both'}} />
                                                                            
                                                                            </div>
                                                                            <p className="col-xs-12 d-flex align-items-center term-condition-line">
                                                                                <input onChange={this.handleOnAcceptTermsAndConditionInputChangeForCred} checked={ this.state.isCredTermsAndConditionChecked } name="isCredTermsAndCondition" type="checkbox" />
                                                                                &nbsp;Acepto los <Link to="#" onClick={this.termsAndCOnditionsPopupOpen}>terminos y condiciones de venta</Link> y <Link to="#" onClick={this.privacyPolicyPopupOpen}>Políticas de Privacidad</Link>
                                                                                <br/>
                                                                            </p>
                                                                            <span style={{color:'red'}}>{this.state.errors["credTermsAndConditionError"]}</span>
                                                                            <div className="blog-buttton">
                                                                                <Button type="submit" className="btn btn-primary btn-small" disabled={this.state.credButtonDisabled}>
                                                                                    Realizar pedido
                                                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                                                </Button>
                                                                            </div>
                                                                            <div style={{clear: 'both'}} />
                                                                        </Form>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            {showBacCredomatic}
                                                            <Card className="payment-card" style={{cursor:'default'}}>
                                                                <Card.Header className="px-0 py-3">
                                                                    <Accordion.Toggle eventKey="1" className="toggle_btn">
                                                                        <input type="radio" name="paymentMethod" className="payment_radio_btn" value="bank_transfer" />
                                                                    </Accordion.Toggle>
                                                                    &nbsp;<strong className="payment_title_txt">Transferencia Bancaria / ACH</strong>
                                                                    <img className="bnktrns_img" src="https://www.evisionstore.com/images/bank-transfer.png" alt=""/>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <Card.Body>
                                                                        <div className="col-xs-12 col-sm-12">
                                                                        <p>Todas las transacciones son seguras y encriptadas, y nunca almacenamos la información de su tarjeta de crédito / débito. Para obtener más información, consulte nuestra política de privacidad.</p>
                                                                        <h5 className="payment-small-tittle">Detalles del banco:</h5>
                                                                        <p>Datos de la cuenta Bancaria:<br/>Banco General<br/>
                                                                            N&#250;mero de cuenta: 03-72-01-059285-6<br/>
                                                                            Nombre: E Vision S.A<br/>
                                                                            Cuenta Corriente  <br/>
                                                                            Correo de confirmacion: dp@evisionstore.com <br/>
                                                                            Colocar su nombre, numero y productos comprados en la descripcion de la transferencia
                                                                        </p>
                                                                        <p className="short-p">En cuanto usted haya depositado, debe enviar el comprobante de pago a la direcci&#243;n <a href = "mailto:dp@evisionstore.com"> dp@evisionstore.com</a>. La venta se completa al recibir el segundo correo de confirmacion de venta. De no tener disponibilidad del producto, la responsabilidad de la empresa se limita a devolverle la suma completa pagada.</p>
                                                                        </div>
                                                                        <p className="col-xs-12 d-flex align-items-center term-condition-line">
                                                                            <input onChange={this.handleOnAcceptTermsAndConditionInputChangeForBank} checked={ this.state.isBankTermsAndConditionChecked } name="isBankTermsAndCondition" type="checkbox" />
                                                                            &nbsp;Acepto los <Link to="#" onClick={this.termsAndCOnditionsPopupOpen}>terminos y condiciones de venta</Link> y <Link to="#" onClick={this.privacyPolicyPopupOpen}>Políticas de Privacidad</Link>
                                                                            <br/>
                                                                        </p>
                                                                        <span style={{color:'red'}}>{this.state.errors["bankTermsAndConditionError"]}</span>
                                                                        <div style={{clear: 'both'}} />
                                                                        <div className="blog-buttton">
                                                                            <button onClick={this.handleOnBankTransferFormSubmit} className="btn btn-primary btn-small" disabled={this.state.bankButtonDisabled}>
                                                                                Realizar pedido
                                                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                                            </button>
                                                                        </div>
                                                                        <div style={{clear: 'both'}} />
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card className="payment-card" style={{cursor:'pointer'}}>
                                                                <Card.Header className="px-0 py-3">
                                                                    <Accordion.Toggle eventKey="2" className="toggle_btn">
                                                                        <input type="radio" name="paymentMethod" className="payment_radio_btn" value="yappy" />
                                                                    </Accordion.Toggle>
                                                                    &nbsp;<strong className="payment_title_txt">Yappy</strong>
                                                                    <img className="yappy_img" src="https://www.evisionstore.com/images/yappy.png" alt=""/>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="2">
                                                                    <Card.Body>
                                                                        <div className="col-xs-12 col-sm-12">
                                                                        <p>Todas las transacciones son seguras y encriptadas, y nunca almacenamos la información de su tarjeta de crédito / débito. Para obtener más información, consulte nuestra política de privacidad.</p>
                                                                        <p>
                                                                            <img src="http://evisionstore.com/images/yappy.png" width="100px" height="100px" alt="" style={{margin: '8px 0 15px'}}/>
                                                                            <span style={{verticalAlign: 'inherit', position: 'relative', top: '25px', left: '10px'}}>
                                                                                <font style={{verticalAlign: 'inherit'}}>
                                                                                    <font style={{verticalAlign: 'inherit'}}>@Evision</font>
                                                                                </font>
                                                                            </span>
                                                                        </p>
                                                                        </div>
                                                                        <p className="col-xs-12 d-flex align-items-center term-condition-line">
                                                                            <input onChange={this.handleOnAcceptTermsAndConditionInputChangeForYappy} checked={ this.state.isYappyTermsAndConditionChecked } name="isYappyTermsAndCondition" type="checkbox" />
                                                                            &nbsp;Acepto los <Link to="#" onClick={this.termsAndCOnditionsPopupOpen}>terminos y condiciones de venta</Link> y <Link to="#" onClick={this.privacyPolicyPopupOpen} >Políticas de Privacidad</Link>
                                                                            <br/>
                                                                        </p>
                                                                        <span style={{color:'red'}}>{this.state.errors["yappyTermsAndConditionError"]}</span>
                                                                        <div style={{clear: 'both'}} />
                                                                        <div className="blog-buttton">
                                                                            <button onClick={this.handleOnYappyFormSubmit} className="btn btn-primary btn-small" disabled={this.state.yappyButtonDisabled}>
                                                                                Realizar pedido
                                                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                                            </button>
                                                                        </div>
                                                                        {/* {this.state.cartProductsArray.map((products, index) => (
                                                                            products.modelo === "gp15g2u4" ? (
                                                                            <div className="pull-right" style={{paddingRight: '15px'}}>
                                                                                <button onClick={this.handleOnYappyFormSubmit} className="btn btn-primary btn-small" disabled={this.state.yappyButtonDisabled}>
                                                                                    Realizar pedido &nbsp; <i className="fa fa-arrow-circle-right" /> 
                                                                                </button>
                                                                            </div>
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        ))} */}
                                                                        <div style={{clear: 'both'}} />
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card className="payment-card" style={{cursor:'default'}}>
                                                                <Card.Header className="px-0 py-3">
                                                                    <Accordion.Toggle eventKey="3" className="toggle_btn">
                                                                        <input type="radio" name="paymentMethod" className="payment_radio_btn" value="clave" />
                                                                    </Accordion.Toggle>
                                                                    &nbsp;<strong className="payment_title_txt">Tarjeta Clave</strong>
                                                                    {/* <img className="card_img" src="https://www.evisionstore.com/images/clave.png" alt=""/> */}
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="3" id="accordionFourth">
                                                                    <Card.Body>
                                                                        <div className="col-xs-12 col-sm-12">
                                                                        <p>Todas las transacciones son seguras y encriptadas, y nunca almacenamos la información de su tarjeta de crédito / débito. Para obtener más información, consulte nuestra política de privacidad.</p>
                                                                        <p>
                                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/key-card.png`} alt="Key Card" /> 
                                                                            {/* <img src="http://evisionstore.com/images/clave.png" width="100px" height="100px" alt=""/> */}
                                                                        </p>
                                                                        </div>
                                                                        <p className="col-xs-12 d-flex align-items-center term-condition-line">
                                                                            <input onChange={this.handleOnAcceptTermsAndConditionInputChangeForClave} checked={ this.state.isClaveTermsAndConditionChecked } name="isClaveTermsAndCondition" type="checkbox" />
                                                                            &nbsp;Acepto los <Link to="#" onClick={this.termsAndCOnditionsPopupOpen}>terminos y condiciones de venta</Link> y <Link to="#"  onClick={this.privacyPolicyPopupOpen}>Políticas de Privacidad</Link>
                                                                            <br/>
                                                                        </p>
                                                                        <span style={{color:'red'}}>{this.state.errors["claveTermsAndConditionError"]}</span>
                                                                        <div style={{clear: 'both'}} />
                                                                        <div className="blog-buttton">
                                                                            <button onClick={this.handleOnClaveFormSubmit} className="btn btn-primary btn-small" disabled={this.state.claveButtonDisabled}>
                                                                                Realizar pedido
                                                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                                            </button>
                                                                        </div>
                                                                        <div style={{clear: 'both'}} />
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cartFooter w100">
                                            <div className="box-footer">
                                                <div className="pull-left">&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Link to="#" onClick={e => this.handleBackToPrevious("back_to_delivery")} className="btn btn-warning"> 
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-prev.svg`} alt="arrow" /> 
                                                        Volver al tipo de entrega 
                                                    </Link>
                                                </div>
                                                {/* <div className="pull-right">
                                                        <Link to="#" className="btn btn-primary btn-small" onClick={this.handleOnBillingShippingFormSubmit}>
                                                            Proceder a Pagar &nbsp; <i className="fa fa-arrow-circle-right" /> 
                                                        </Link>
                                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 rightSidebar">
                                <div className="contentBox">
                                    <div className="w100 costDetails">
                                        {/* <div className="col-lg-12">
                                            <h4 className="block-title-2">Resumen del pedido</h4>
                                        </div> */}
                                        <div className="table-block checkout-table-block" id="order-detail-content">
                                            <h4 className="title-table">Resumen del pedido</h4>
                                            <div className="w100 cartMiniTable">
                                                <table id="cart-summary" className="std table">
                                                    <thead>
                                                        <tr>
                                                            <th width="40%">Modelo</th>
                                                            <th style={{textAlign:'right'}}>Precio</th>
                                                            <th style={{textAlign:'center'}}>Cant.</th>
                                                            <th width="25%" style={{textAlign:'left'}}>Total Parcial</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="blank-tr">
                                                            <td colSpan="4"></td>
                                                        </tr>
                                                        {!isLoggedIn ? (
                                                                cartProducts.map((products, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{textAlign:'left'}}>{products.prodName}</td>
                                                                        <td style={{textAlign:'right'}}>${this.financial(products.prodPrice)}</td>
                                                                        <td style={{textAlign:'center'}}>{products.prodQuantity}</td>
                                                                        <td style={{textAlign:'left'}}>${this.financial(products.prodPrice * products.prodQuantity)}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                this.state.cartProductsArray.map((products, index) => (
                                                                    <>
                                                                    <Helmet>
                                                                        <script>
                                                                        {`
                                                                        ga('ec:addProduct', {
                                                                        'id': "${products.modelo}",
                                                                        'name': "${products.marca} ${products.modelo}",
                                                                        'category': '',
                                                                        'brand': "${products.marca}",
                                                                        'price': ${products.formated_total_price},
                                                                        'quantity': ${products.qty}
                                                                        });
                                                                        `}
                                                                        </script>
                                                                    </Helmet>
                                                                    <tr key={index}>
                                                                        <td style={{textAlign:'left'}}>
                                                                            {products.model}
                                                                            {products.coupon_text !== "" ? (
                                                                                <p dangerouslySetInnerHTML={{
                                                                                    __html: products.coupon_text
                                                                                    }}>
                                                                                </p>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>
                                                                        <td style={{textAlign:'right'}}>{products.currency}{this.financial(products.price)}</td>
                                                                        <td style={{textAlign:'center'}}>{products.qty}</td>
                                                                        <td style={{textAlign:'left'}}>{products.currency}{products.total}</td>
                                                                    </tr>
                                                                    </>
                                                                ))
                                                            )
                                                        }
                                                        <Helmet>
                                                            <script>
                                                            {`
                                                            ga("ec:setAction", "checkout");
                                                            ga("send", "event", "checkout", "click", "checkout");
                                                            ga('send', 'pageview');
                                                            `}
                                                            </script>
                                                        </Helmet>
                                                        
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="3">Total parcial</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(totalProductPrice)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">{taxPercentage}</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(totalTaxAmount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">Costo de envío {`(${customerDeliveryType})`}</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(deliveryCharge)} </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan="3">Discount </td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(discountAmount)}</td>
                                                        </tr> */}
                                                        <tr className="last-row">
                                                            <td colSpan="3"><strong>Total a Pagar</strong></td>
                                                            <td className="site-color" id="total-price" style={{textAlign:'left'}}>
                                                                <strong>{currencyFormat}{this.financial(totalAmount)}</strong>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            {/* <div className="table-button">
                                                <Link to="#" className="btn btn-primary btn-small" onClick={this.handleOnBillingShippingFormSubmit}>
                                                    Proceder a Pagar
                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="couponblock general-card-wrapper">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="formGroupCouponCode">Tiene un cupón de descuento? *</label>
                                        <input name="couponCode" onChange={this.handleOnInputChange} placeholder="Introduce el código de cupón" id="formGroupCouponCode" className="form-control" value={couponCode} />
                                        <span style={{color: 'red'}}>{this.state.errors["couponCode"]}</span>
                                        <span style={{color: 'green'}}>{this.state.responseSuccess["couponCode"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <Link to="#" onClick={this.handleOnCouponSubmit} className="btn btn-primary" disabled={this.state.couponButtonDisabled}> 
                                            Aplicar 
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className={`loader-container ${this.state.paymentLoader}`}>
                    <div className="loader" />	
                </section>
                <div className="line-footer m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showTermsAndConditionsModal} onHide={this.termsAndCOnditionsPopupClose} animation={true} dialogClassName="terms-privacy-modal">                          
                    <Modal.Header closeButton>
                        <Modal.Title>terminos y condiciones de venta y</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><TermsAndConditions /></Modal.Body>     
                </Modal>

                <Modal show={this.state.showPrivacyPolicyModal} onHide={this.privacyPolicyPopupClose} animation={true} dialogClassName="terms-privacy-modal">                          
                    <Modal.Header closeButton>
                        <Modal.Title>Políticas de Privacidad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><PrivacyPolicy /></Modal.Body>     
                </Modal>
                </>
            );
        } else if(!isBillingShippingFormSubmitted){
            return (
                <>
                <section className="dashboard-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-center p-0 mb-4">
                                <div className="px-0 pb-0 mt-3">
                                    <form id="form">
                                        <ul id="progressbar">
                                            <li className="active" id="step1">
                                                <strong>Datos Personales</strong>
                                            </li>
                                            <li id="step2"><strong>Información de Pago</strong></li>
                                            <li id="step3"><strong>Confirmación</strong></li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                {/* <h3 className="section-title-inner">
                                    <span>Revisa</span>
                                </h3> */}
                                <div className="row userInfo">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="userInfo card card-outline card-info mb-4 py-4">
                                            <div className="col-lg-12">
                                                <h4 className="block-title-2 pb-3 px-3">
                                                    <span className="top-0 start-100 translate-middle badge bg-secondary">&nbsp;1&nbsp;</span> 
                                                    {(() => {
                                                        if(this.state.isShippingAsBillingChecked){
                                                            return(
                                                                <span>&nbsp;Información de facturación</span>
                                                            )
                                                        }else {
                                                            return(
                                                                <span>&nbsp;Información De facturación y envío</span>
                                                            )
                                                        }
                                                    })()}
                                                </h4>
                                            </div>
                                            <form className="col-xs-12 col-sm-12">
                                                {(() => {
                                                    if (!customerId) {
                                                        return(
                                                            <>
                                                                <div className="col-xs-12 col-sm-12">
                                                                    <div className="form-group required">
                                                                        <label htmlFor="InputName">Email Id <sup className="required-field">*</sup> </label>
                                                                        <input type="email" name="billingEmail" value={billingEmail} onChange={this.handleOnInputChange} maxLength="70" className="form-control" id="InputEmail" placeholder="Correo Electrónico" />
                                                                        <span style={{color: "red"}}>{this.state.errors["billingEmail"]}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            )
                                                    } else {
                                                        return null;
                                                    }
                                                })()} 
                                                
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group required">
                                                        {/* <label htmlFor="InputName">Nombre de pila <sup className="required-field">*</sup> </label> */}
                                                        <input type="text" name="billingFirstName" value={billingFirstName} onChange={this.handleOnInputChange} maxLength="50" className="form-control" id="InputName" placeholder="Nombre de pila *" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingFirstName"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label htmlFor="InputMobile">Telefono <sup className="required-field">*</sup></label> */}
                                                        {/* <input type="tel" name="billingPhone" value={billingPhone} onChange={this.handleOnBillingTelInputChange} maxLength="12" className="form-control" id="InputMobile" placeholder="Telephone" /> */}
                                                        <IntlTelInput containerClassName="intl-tel-input" inputClassName="form-control" fieldName="billingPhone" fieldId="InputMobile" value={billingPhone} onPhoneNumberChange={(...args) =>{this.handleOnBillingTelInputChange(...args)}} placeholder="Telefono" maxLength="15" style={{width:'100%'}} separateDialCode format={true} defaultCountry="pa"/>
                                                        <span style={{color: "red"}}>{this.state.errors["billingPhone"]}</span>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="InputEmail">Correo electrónico <sup className="required-field">*</sup></label>
                                                        <input type="text" name="billingEmail" value={billingEmail} onChange={this.handleOnInputChange} maxLength="70" className="form-control" id="InputEmail" placeholder="Correo electrónico" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingEmail"]}</span>
                                                    </div> */}
                                                    
                                                    {/* <div className="form-group">
                                                        <label htmlFor="InputCompany">Nombre de empresa </label>
                                                        <input type="text" name="billingCompany" value={billingCompany} onChange={this.handleOnInputChange} maxLength="150" className="form-control" id="InputCompany" placeholder="Nombre de empresa" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingCompany"]}</span>
                                                    </div> */}
                                                    <div className="form-group required">
                                                        {/* <label htmlFor="InputAddress">Direccion (Línea 1) <sup className="required-field">*</sup> </label> */}
                                                        <input type="text" name="billingAddress1" value={billingAddress1} onChange={this.handleOnInputChange} maxLength="150" className="form-control" id="InputAddress" placeholder="Direccion 1 *" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingAddress1"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label htmlFor="InputAddress2">Direccion (Línea 2) </label> */}
                                                        <input type="text" name="billingAddress2" value={billingAddress2} onChange={this.handleOnInputChange} maxLength="150" className="form-control" id="InputAddress2" placeholder="Direccion 2" />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group required">
                                                        {/* <label htmlFor="InputLastName">Apellido <sup className="required-field">*</sup> </label> */}
                                                        <input required type="text" name="billingLastName" value={billingLastName} onChange={this.handleOnInputChange} maxLength="50" className="form-control" id="InputLastName" placeholder="Apellido *" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingLastName"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label htmlFor="InputCountry">Provincia <sup className="required-field">*</sup> </label> */}
                                                        <select value={billingCountry} onChange={this.handleOnCountryChange} className="form-control select2-hidden-accessible" id="InputCountry" name="InputCountry" aria-hidden="true">
                                                            <option value="">Seleccione Provincia</option>
                                                            {countryItems.map(country => 
                                                                <option value={country.country_id} key={country.country_id}>{country.country_name}</option>
                                                            )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["billingCountry"]}</span>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="InputState">Distrito <sup className="required-field">*</sup> </label>
                                                        <select value={billingState} onChange={this.handleOnStateChange} className="form-control select2-hidden-accessible" name="billingState" aria-hidden="true">
                                                        <option value="">Seleccione Distrito</option>
                                                        {stateItems.map(provins => 
                                                            <option value={provins.state_id} key={provins.state_id}>{provins.state_name}</option>
                                                        )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["billingState"]}</span>
                                                    </div> */}
                                                    <div className="form-group">
                                                        {/* <label htmlFor="InputCity">Corregimientos <sup className="required-field">*</sup> </label> */}
                                                        <select value={billingCity} onChange={this.handleOnCityChange} className="form-control select2-hidden-accessible" name="billingCity" aria-hidden="true">
                                                        <option value="">Seleccione Corregimientos</option>
                                                        {cityItems.map(city => 
                                                            <option value={city.city_id} key={city.city_id}>{city.city_name}</option>
                                                        )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["billingCity"]}</span>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="InputZip">Código postal <sup>*</sup> </label>
                                                        <input type="text" name="billingZipCode" value={billingZipCode} onChange={this.handleOnInputChange} maxLength="7" className="form-control" id="InputZip" placeholder="Código postal" />
                                                        <span style={{color: "red"}}>{this.state.errors["billingZipCode"]}</span>
                                                    </div> */}
                                                    
                                                    {/* <div className="form-group">
                                                        <label htmlFor="addressAlias">Asigne una dirección para referencia futura.</label>
                                                        <input type="text" name="billingAddressTitle" value={billingAddressTitle} onChange={this.handleOnInputChange} className="form-control" id="addressAlias" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="InputAdditionalInformation">Información Adicional</label>
                                                        <textarea rows={4} cols={26}  name="billingAddInfo" value={billingAddInfo} onChange={this.handleOnInputChange} maxLength="150" className="form-control" id="InputAdditionalInformation" />
                                                    </div> */}
                                                </div>
                                            </form>
                                            {/* <div className="col-lg-12">
                                                <hr />
                                                <div style={{minHeight:"260px"}}>
                                                <Map google={this.props.google} zoom={16} style={mapStyles}
                                                    initialCenter={{
                                                        lat: 8.9823792,
                                                        lng: -79.5198696
                                                    }}>
                                                    <Marker
                                                    onClick={this.onMarkerClick}
                                                    name={'Evision Store'}
                                                    />
                                                    <InfoWindow
                                                    marker={this.state.activeMarker}
                                                    visible={this.state.showingInfoWindow}
                                                    onClose={this.onClose}
                                                    >
                                                    <div>
                                                        <h4 style={{fontSize:'15px'}}>{this.state.selectedPlace.name}</h4>
                                                    </div>
                                                    </InfoWindow>
                                                </Map>
                                                </div>
                                            </div> */}
                                        </div>
                                        
                                        <div className="row userInfo">
                                            <div className="col-xs-12">
                                                <div className="form-group d-flex align-items-center same-checked">
                                                    <input id="shipadd" onChange={this.handleOnShippingInputChange} checked={ this.state.isShippingAsBillingChecked } name="isShippingAsBilling" defaultValue={1} type="checkbox" style={{width:'18px',height:'18px',position:'relative',top:'5px'}}/>
                                                    <label htmlFor="shippingFirstName">Mi dirección de facturación y envío son diferentes</label>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className={`row userInfo card card-outline card-info mb-4 py-4 ${hidden}`}>
                                            <div className="col-lg-12">
                                                <h4 className="block-title-2 pb-3 px-3">
                                                    <span className="top-0 start-100 translate-middle badge bg-secondary">&nbsp;2&nbsp;</span> Información del Envío
                                                </h4>
                                            </div>
                                            <form className="col-xs-12 col-sm-12">
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="shippingFirstName">Nombre de pila <sup className="required-field">*</sup> </label>
                                                        <input type="text" name="shippingFirstName" value={shippingFirstName} onChange={this.handleOnInputChange} maxLength="50" className="form-control" id="shippingFirstName" placeholder="Nombre de pila" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingFirstName"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="shippingPhone">Telefono <sup className="required-field">*</sup></label>
                                                        {/* <input type="tel" name="shippingPhone" value={shippingPhone} onChange={this.handleOnInputChange} maxLength="10" className="form-control" id="shippingPhone" placeholder="Telefono" /> */}
                                                        <IntlTelInput containerClassName="intl-tel-input" inputClassName="form-control" fieldName="shippingPhone" fieldId="shippingPhone" value={shippingPhone} onPhoneNumberChange={(...args) =>{this.handleOnShippingTelInputChange(...args)}} placeholder="Telephone" maxLength="50" style={{width:'100%'}} separateDialCode format={true} defaultCountry="pa" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingPhone"]}</span>
                                                    </div>
                                                    
                                                    {/* <div className="form-group">
                                                        <label htmlFor="shippingEmail">Correo electrónico </label>
                                                        <input type="text" name="shippingEmail" value={shippingEmail} onChange={this.handleOnInputChange} maxLength="70" className="form-control" id="shippingEmail" placeholder="Correo electrónico" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingEmail"]}</span>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="shippingAddress1">Dirección (Línea 1) <sup className="required-field">*</sup> </label>
                                                        <input type="text" name="shippingAddress1" value={shippingAddress1} onChange={this.handleOnInputChange} maxLength="200" className="form-control" id="shippingAddress" placeholder="Dirección 1" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingAddress1"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="shippingAddress2">Dirección (Línea 2) </label>
                                                        <input type="text" name="shippingAddress2" value={shippingAddress2} onChange={this.handleOnInputChange} maxLength="200" className="form-control" id="shippingAddress2" placeholder="Dirección 2" />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6">
                                                    {/* <div className="form-group">
                                                        <label htmlFor="shippingZipCode">Código postal <sup>*</sup> </label>
                                                        <input type="text" name="shippingZipCode" value={shippingZipCode} onChange={this.handleOnInputChange} maxLength="10" className="form-control" id="shippingZipCode" placeholder="Código postal" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingZipCode"]}</span>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="shippingLastName">Apellido <sup className="required-field">*</sup> </label>
                                                        <input type="text" name="shippingLastName" value={shippingLastName} onChange={this.handleOnInputChange} maxLength="50" className="form-control" id="shippingLastName" placeholder="Apellido" />
                                                        <span style={{color: "red"}}>{this.state.errors["shippingLastName"]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="shippingCountry">Provincia <sup className="required-field">*</sup> </label>
                                                        <select value={shippingCountry} id="shippingCountry" name="shippingCountry" onChange={this.handleOnShippingCountryChange} className="form-control select2-hidden-accessible" aria-hidden="true">
                                                            <option value="">Seleccione Provincia</option>
                                                            {countryItems.map(country => 
                                                                <option value={country.country_id} key={country.country_id}>{country.country_name}</option>
                                                            )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["shippingCountry"]}</span>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="shippingState">Distrito <sup className="required-field">*</sup> </label>
                                                        <select value={shippingState} onChange={this.handleOnShippingStateChange} name="shippingState" id="shippingState" className="form-control select2-hidden-accessible" aria-hidden="true">
                                                        <option value="">Seleccione Distrito</option>
                                                        {shippingStateItems.map(provins => 
                                                            <option value={provins.state_id} key={provins.state_id}>{provins.state_name}</option>
                                                        )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["shippingState"]}</span>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="shippingCity">Corregimientos <sup className="required-field">*</sup> </label>
                                                        <select value={shippingCity} onChange={this.handleOnShippingCityChange} name="shippingCity" id="shippingCity" className="form-control select2-hidden-accessible"aria-hidden="true">
                                                        <option value="">Seleccione Corregimientos</option>
                                                        {shippingCityItems.map(city => 
                                                            <option value={city.city_id} key={city.city_id}>{city.city_name}</option>
                                                        )}
                                                        </select>
                                                        <span style={{color: "red"}}>{this.state.errors["shippingCity"]}</span>
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                        </div>
                                        <div className="userInfo card card-outline card-info mb-3 py-4">
                                            <div className="col-lg-12">
                                                <h4 className="block-title-2 pb-3 px-3">
                                                    <span className="top-0 start-100 translate-middle badge bg-secondary">
                                                    {(() => {
                                                        if(this.state.isShippingAsBillingChecked){
                                                            return(
                                                                <>&nbsp;3&nbsp;</>
                                                            )
                                                        }else {
                                                            return(
                                                                <>&nbsp;2&nbsp;</>
                                                            )
                                                        }
                                                    })()}
                                                    </span> Cómo desea recibir su mercancía?
                                                </h4>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="w100 clearfix">
                                                    <form>
                                                    <div className="col-xs-12 col-sm-12">
                                                        <div className="form-group">
                                                            {/* <label htmlFor="delivery-choose">Elija entrega</label> */}
                                                            <select id="delivery-choose" className="form-control" value={deliveryType} onChange={this.handleOnDeliveryTypeChange}>
                                                                <option value={0}>Selecciona una opción</option>
                                                                <option value={1}>Recoger en tienda</option>
                                                                <option value={2}>Entrega a Domicilio</option>
                                                            </select>
                                                            <span style={{color: "red"}}>{this.state.errors["deliveryTypeError"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className={`col-xs-12 col-sm-12 ${ pickupShowHidden }`}>
                                                        <div className="form-group">
                                                            <label htmlFor="delivery-choose" className="bol-label">Pickup From Below Dirección de la tienda</label>
                                                            {deliveryTypeItems.map((deliveryitem, index) => (
                                                            <div className="form-check" key={index}>
                                                                <label className="form-check-label">
                                                                    <input className="form-check-input" onChange={e => this.handleOnPickupTypeInputChange(deliveryitem.pickup_store_id)} checked={ deliveryitem.pickup_store_id === pickupStoreId } name="selectedDeliveryType" value={deliveryitem.pickup_store_id} type="checkbox" /> &nbsp;&nbsp;&nbsp; {deliveryitem.pickup_store_name}
                                                                </label>
                                                            </div> 
                                                            ))}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="delivery-choose" className="bottom-note"><strong>Nota:</strong> Llama a la tienda para confirmar la recogida.</label>
                                                        </div>
                                                    </div>
                                                    <div className={`col-xs-12 col-sm-12 ${ deliveryShowHidden }`}>
                                                        {/* <div className="form-group">
                                                            <label htmlFor="delivery-choose">Choose Delivery</label>
                                                        </div> */}
                                                        {deliveryTypeItems.map((deliveryprice, index) => (
                                                            deliveryprice.delivery_cost_without_format === deliveryCost ? (
                                                                <div className="form-group" key={index}>
                                                                    <input onChange={e => this.handleOnDeliveryTypeInputChange(deliveryprice.zone_id, deliveryprice.delivery_cost)} name="selectedDeliveryType" checked='checked' value={deliveryprice.delivery_cost} type="radio" />
                                                                    <label htmlFor="deliveryType">&nbsp; Delivery Cost {deliveryprice.currency}{deliveryprice.delivery_cost}</label>  
                                                                </div> 
                                                            ) : (
                                                                <div className="form-group" key={index}>
                                                                    <input onChange={e => this.handleOnDeliveryTypeInputChange(deliveryprice.zone_id, deliveryprice.delivery_cost)} name="selectedDeliveryType" checked='checked' value={deliveryprice.delivery_cost} type="radio" />
                                                                    <label htmlFor="deliveryType">&nbsp; Delivery Cost {deliveryprice.currency}{deliveryprice.delivery_cost}</label>  
                                                                </div> 
                                                            )
                                                        ))}
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row cartFooterLatest w100">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="box-footer">
                                                    <div className="pull-left">
                                                        <Link to="/cart" className="btn btn-default">
                                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-prev.svg`} alt="arrow" /> 
                                                             Volver al carrito 
                                                        </Link>
                                                    </div>
                                                    <div className="pull-right">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            
                            <div className="col-lg-5 col-md-5 col-sm-12 rightSidebar">
                                {/* <h3 className="section-title-inner">
                                    <span className="caps">Resumen del pedido</span>
                                </h3> */}
                                <div className="row contentBox">
                                    <div className="col-lg-12 col-md-12 col-sm-12 w100 costDetails">
                                        <div className="table-block checkout-table-block" id="order-detail-content">
                                            <h4 className="title-table">Resumen del pedido</h4>
                                            <div className="w100 cartMiniTable">
                                                <table id="cart-summary" className="std table">
                                                    <thead>
                                                        <tr>
                                                            <th width="40%">Modelo</th>
                                                            <th style={{textAlign:'right'}}>Precio</th>
                                                            <th style={{textAlign:'center'}}>Cant.</th>
                                                            <th width="25%" style={{textAlign:'left'}}>Total Parcial</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="blank-tr">
                                                            <td colSpan="4"></td>
                                                        </tr>
                                                        {!isLoggedIn ? (
                                                                cartProducts.map((products, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{textAlign:'left'}}>{products.prodName}</td>
                                                                        <td style={{textAlign:'right'}}>${this.financial(products.prodPrice)}</td>
                                                                        <td style={{textAlign:'center'}}>{products.prodQuantity}</td>
                                                                        <td style={{textAlign:'left'}}>${this.financial(products.prodPrice * products.prodQuantity)}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                this.state.cartProductsArray.map((products, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{textAlign:'left'}}>
                                                                            {products.model}
                                                                            {products.coupon_text !== "" ? (
                                                                                <p dangerouslySetInnerHTML={{
                                                                                    __html: products.coupon_text
                                                                                    }}>
                                                                                </p>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>
                                                                        <td style={{textAlign:'right'}}>{products.currency}{this.financial(products.price)}</td>
                                                                        <td style={{textAlign:'center'}}>{products.qty}</td>
                                                                        <td style={{textAlign:'left'}}>{products.currency}{products.total}</td>
                                                                    </tr>
                                                                ))
                                                            )
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="3">Total Parcial</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(totalProductPrice)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">{taxPercentage}</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(totalTaxAmount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3">Costo de envío {customerDeliveryType && `(${customerDeliveryType})`}</td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(deliveryCharge)} </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan="3">Discount </td>
                                                            <td className="price" style={{textAlign:'left'}}>{currencyFormat}{this.financial(discountAmount)}</td>
                                                        </tr> */}
                                                        <tr className="last-row">
                                                            <td colSpan="3"><strong>Total a Pagar</strong></td>
                                                            <td className="site-color" id="total-price" style={{textAlign:'left'}}>
                                                                <strong>{currencyFormat}{this.financial(totalAmount)}</strong>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="table-button">
                                                <Link to="#" className="btn btn-primary btn-small" onClick={this.handleOnBillingShippingFormSubmit}>
                                                    Proceder a Pagar
                                                    <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`} alt="arrow" /> 
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`loader-container ${this.state.paymentLoader}`}>
                    <div className="loader" />	
                </section>  
                <div className="line-footer m-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                    </div>                                              
                </>
            );
        }
    }
}

function mapStateToProps(state) {
    return { 
        isLoggedIn: state.user.isLoggedIn,
        cartProducts: [...state.cart.cartData],
        cartCount: state.cart.cartCount,
        cartAttributes: [...state.cart.cartAttributesData],
        customerId: (typeof(state.user.user) === 'undefined') ? "" :  state.user.user.customer_id,
        countryId: state.checkout.billingInfo.billingCountry,
        stateId: state.checkout.billingInfo.billingState,
        cityId: state.checkout.billingInfo.billingCity,
        billingInfo: state.checkout.billingInfo,
        shippingInfo: state.checkout.shippingInfo,
        paymentStatus: state.checkout.paymentStatus,
        placeorderInfo: state.checkout.placeorderInfo
    }
}

const actionCreators = {
    addBillingInfo: addBillingInfo,
    addShippingInfo: addShippingInfo,
    submitCardPaymentForm: submitCardPaymentForm,
    submitBacCardPaymentForm: submitBacCardPaymentForm,
    submitBankTransferForm: submitBankTransferForm,
    submitYappyForm: submitYappyForm,
    submitClaveForm: submitClaveForm
};

const CheckoutComp = connect(
    mapStateToProps,
    actionCreators
)(CheckoutListingSection)

export default CheckoutComp;

// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyCW9LXMnc8_pfxmRJ0rAymb1R36kyJr4Ro'
//   })(CheckoutComp);