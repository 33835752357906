import React, { Component } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import API_BASE_URL from "../../config/api";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { addToCart, addToCart2 } from "../../Services/Actions/cart.actions";

class RecomendedShopSectionProp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            recomendedProducts: [],
            isRecomendedProductLoaded: false,
            isCartAddError: false,
            cartErrorMessage: "",
            addToCartButtonnLoader: "hidden",
            addToCartButtonnCart: "",
            addToCartButton: true,
            addToCartButtonDisabled: false,
            clickedProductId: "",
            categoryProductName: "",
        };

        this.openCartReturnPop = this.openCartReturnPop.bind(this);
        this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
        this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
        this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
    }

    async componentDidMount() {
        //const apiUrl = `${API_BASE_URL}/products-by-category`;
        const apiUrl = `${API_BASE_URL}/products-by-brand`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({"brand": "midea"})
        };
        try {
            const response = await fetch(apiUrl, requestOptions);
            const result = await response.json();
            this.setState({
                recomendedProducts: result.data,
                isRecomendedProductLoaded: true,
            });
        } catch (error) {
            this.setState({ error: error });
        }
    }

    openCartReturnPop() {
        this.setState({ showCartReturnModal: true });
    }

    closeCartReturnPop() {
        this.setState({ showCartReturnModal: false });
    }

    cartUpdatePopupResOpen() {
        this.setState({ showUpdateCartMsgModal: true });
    }

    cartUpdatePopupResClose() {
        this.setState({ showUpdateCartMsgModal: false });
    }

    handleOnAddToCart(product_id, product_name, product_image, price, quantity, brand, modelo, category_name, is_allowed_bac_credomatic) {
        this.setState({
            addToCartButtonnLoader: "",
            addToCartButtonnCart: "hidden",
            addToCartButton: true,
            addToCartButtonDisabled: true,
            cartErrorMessage: "",
            clickedProductId: product_id,
        });
        let checked_variation_product_id = 0;
        this.props.addToCart(product_id, product_name, product_image, price, quantity, checked_variation_product_id, modelo, is_allowed_bac_credomatic);

        setTimeout(
            () =>
            this.setState(
                {
                isCartAddError: this.props.isCartAddError,
                },
                () => {
                if (this.state.isCartAddError) {
                    this.setState({
                    cartErrorMessage: this.props.cartErrorMessage,
                    addToCartButtonnLoader: "hidden",
                    addToCartButtonnCart: "",
                    addToCartButton: true,
                    addToCartButtonDisabled: false,
                    clickedProductId: "",
                    });
                    this.cartUpdatePopupResOpen();
                    setTimeout(() => this.cartUpdatePopupResClose(), 5000);
                } else {
                    this.openCartReturnPop();
                    this.setState({
                    addToCartButtonnLoader: "hidden",
                    addToCartButtonnCart: "",
                    addToCartButton: false,
                    addToCartButtonDisabled: false,
                    cartErrorMessage: "",
                    });

                    window.fbq("track", "AddToCart", {
                    content_ids: [product_id],
                    content_type: "product",
                    value: price,
                    currency: "USD",
                    });

                    window.ga("ec:addProduct", {
                    id: modelo,
                    name: brand + " " + modelo,
                    price: price,
                    brand: brand,
                    category: category_name,
                    quantity: 1,
                    });
                    window.ga("ec:setAction", "add");
                    window.ga("send", "event", "Product List Page", "click", "addToCart");

                    window.gtag("event", "add_to_cart", {
                    send_to: "UA-521381-2/yuuFCLjX-aUBENbiovUC",
                    value: price,
                    currency: "USD",
                    event_callback: true,
                    });
                }
                }
            ),
            3000
        );
    }

  render() {
    const departmentSliderSettings = {
      dots: false,
      arrows: true,
      fade: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      adaptiveHeight: false,
    };

    if (this.state.isRecomendedProductLoaded === false) {
      return (
        <section className="recomended">
            <div className="recomended-top">
                <a href="javascript:void(0)" className="banner-link">
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/upto-70.png`} alt="Upto 70%" />
                </a>
            </div>
            <section className="customer-logos">
            {Array(3)
                .fill()
                .map((item, index) => (
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6" key={index}>
                    <div className="deal-link-item deal-link-item--skeleton">
                    <Skeleton height={215} width={`100%`} style={{ marginBottom: "30px" }} />
                    <Skeleton width={175} style={{ marginBottom: "10px" }} />
                    <Skeleton width={150} style={{ marginBottom: "10px" }} />
                    <Skeleton width={140} style={{ marginBottom: "10px" }} />
                    <Skeleton width={100} style={{ marginBottom: "25px" }} />
                    </div>
                </div>
                ))}
            </section>
        </section>
      );
    } else {
      const { recomendedProducts } = this.state;

      return (
        <section className="recomended">
            <div className="recomended-top">
                <a href="javascript:void(0)" className="banner-link">
                    <img src={`${process.env.PUBLIC_URL}/images/new-images/upto-70.png`} alt="Upto 70%" />
                </a>
            </div>
            <div className="recomended-botttom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="recomended-botttom-slider">
                                <Slider {...departmentSliderSettings}>
                                {recomendedProducts.length !== 0 ? (
                                    recomendedProducts.map((product, index) => (
                                        <div className="recomended-slide" key={index}>
                                            <Link to={`/producto/${product.modelo}`}>
                                                <div className="figure-area">
                                                    <a href="javascript:void(0)" className="wishlist-link">
                                                        <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/wishlist-icon.svg`} width="20" height="18" alt="wishlist" />
                                                    </a>
                                                    <figure>
                                                        <img src={product.product_image} alt={product.product_name} />
                                                    </figure>
                                                </div>
                                                <div className="slide-test-area">
                                                    <h3>{product.product_name}</h3>
                                                    {/* <div className="review"><img src="/images/new-images/icon/star.svg" width="20" height="19" title="star" alt="star" /><span>5.0 | 14 opiniones</span></div> */}
                                                    <div className="pricecart">
                                                        {product.check_spcl_prc === "yes" ? (
                                                                <h5>
                                                                $ {product.special_price} - <span className="oldprice">$ {product.price}</span>
                                                                </h5>
                                                            ) : (
                                                                <h5>$ {product.price}</h5>
                                                            )}
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleOnAddToCart(
                                                                    product.product_id,
                                                                    product.product_name,
                                                                    product.product_image,
                                                                    product.price,
                                                                    "1",
                                                                    product.brand,
                                                                    product.modelo,
                                                                    product.category_name,
                                                                    product.is_allowed_bac_credomatic
                                                                )
                                                            }
                                                                
                                                            }
                                                            disabled={this.state.addToCartButtonDisabled}
                                                            >
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`}
                                                                width="20"
                                                                height="16"
                                                                alt="Cart"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                    </>
                                )}
                                </Slider>
                                <Modal show={this.state.showUpdateCartMsgModal} onHide={this.cartUpdatePopupResClose} animation={true}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Oops!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <p style={{ textAlign: "center" }}>{this.state.cartErrorMessage}</p>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={this.state.showCartReturnModal} onHide={this.closeCartReturnPop} animation={true}>
                                    <Modal.Header>
                                    <button type="button" className="close" onClick={this.closeCartReturnPop}>
                                        &times;
                                    </button>
                                    <Modal.Title>
                                        <i className="fa fa-check" aria-hidden="true"></i> El producto ha sido agregado al carrito.
                                    </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <p style={{ marginTop: "17px", textAlign: "center" }}>
                                        <Link to="/cart" className="btn btn-primary btn-lg">
                                        &nbsp;&nbsp;Proceder a Pagar <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                                        </Link>
                                        <button className="btn btn-success btn-lg" onClick={this.closeCartReturnPop} style={{ margin: "0 0 0 30px" }}>
                                        &nbsp;&nbsp;<i className="fa fa-shopping-basket" aria-hidden="true"></i> Seguir Comprando&nbsp;&nbsp;
                                        </button>
                                    </p>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <div className="col-lg-4 recomended-botttom-text">
                            {/* <h4>Recomendada</h4>
                            <p>Tu estilo, a tu manera: ¡Da rienda suelta a tu fashionista con nuestra selección de comercio electrónico!</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    }
  }
}

function mapStateToProps(state) {
    localStorage.setItem("cartData", JSON.stringify(state.cart.cartData));
    return {
      isLoggedIn: state.user.isLoggedIn,
      loggedInUserEmail: typeof state.user.user === "undefined" ? "" : state.user.user.email,
      isError: state.user.isError,
      errorMessage: state.user.errorMessage,
      cartData: state.cart.cartData,
      filteredCategoryProducts: state.filter.filteredProducts,
      isFilterProductsLoaded: state.filter.isFilterProductsLoaded,
      isCartAddError: state.cart.isCartAddError,
      cartErrorMessage: state.cart.cartErrorMessage,
    };
}
  
  const MapDispachToProps = (dispatch) => ({
    addToCart: () => dispatch(addToCart()),
  });
  
  const actionCreators = {
    addToCart: addToCart2,
    //resetFilterByCategorySlug: resetFilterByCategorySlug,
    //setBrandOptions: setBrandOptions,
    //setProductsById: setProductsById,
  };
  
  const RecomendedShopSection = connect(mapStateToProps, actionCreators)(RecomendedShopSectionProp);
  export default RecomendedShopSection;